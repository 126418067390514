import React, { forwardRef } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, Redirect, Route } from 'react-router-dom';

export const LoggedOutRoute = forwardRef(
  ({ path, component, render, children, redirectTo, ...props }, ref) => {
    const { pathname } = useLocation();

    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);

    let route = '/';

    if (pathname.endsWith('/login')) {
      route = pathname.slice(0, pathname.length - 6);
    }

    return pathname === path || path.includes(pathname) ? (
      <Route path={path} {...props} ref={ref}>
        {isLoggedIn && <Redirect to={!!redirectTo ? redirectTo : route} />}
        {children}
      </Route>
    ) : null;
  },
);
