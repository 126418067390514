import { createSlice } from '@reduxjs/toolkit';

import { snackbarType } from '../../components/SnackBarAlert';

const initialState = {
  networkFetching: false,
  loadingMessage: null,

  snackBarState: {
    message: null,
    type: snackbarType.ERROR,
    duration: 5000,
  },

  // redirectToUserLogin: false,
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setLoading(state, { payload }) {
      state.networkFetching = payload.networkFetching;
      state.loadingMessage = payload.loadingMessage;
    },
    setSnackbar(state, { payload }) {
      state.snackBarState.message = payload.message;
      state.snackBarState.type = payload.type;
      state.snackBarState.duration = payload.duration || 5000;
    },
    // setRedirectToUserLogin(state) {
    //   state.redirectToUserLogin = !state.redirectToUserLogin;
    // },
  },
});

export const { actions: uiActions, reducer: uiReducer } = uiSlice;
