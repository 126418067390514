import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { axios } from '../axios';
import { loginUsingSavedCredentials, logout } from '../store';
import { ACCESS_TOKEN } from '../global-constants';

export const Initialize = ({ children }) => {
  const dispatch = useDispatch();

  const [init, setInit] = useState(false);

  const authToken = useSelector(state => state.auth.authToken);
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);

  useEffect(() => {
    if (localStorage.getItem(ACCESS_TOKEN)) {
      dispatch(loginUsingSavedCredentials());
    }
  }, [dispatch]);

  // Handling Multiple tab logouts
  useEffect(() => {
    const logoutNow = () => {
      if (!localStorage.getItem(ACCESS_TOKEN)) {
        dispatch(logout());
      }
    };

    window.onfocus = logoutNow;

    return () => {
      window.onfocus = null;
    };
  }, [dispatch]);

  // Handling Multiple tab logins
  useEffect(() => {
    const loginNow = () => {
      if (authToken !== localStorage.getItem(ACCESS_TOKEN)) {
        dispatch(loginUsingSavedCredentials());
      }
    };

    window.addEventListener('storage', loginNow);

    return () => {
      window.removeEventListener('storage', loginNow);
    };
  }, [dispatch, authToken]);

  useEffect(() => {
    if (authToken) {
      axios.defaults.headers.common['Authorization'] = authToken;

      setInit(true);
    }
  }, [authToken, dispatch]);

  return !isLoggedIn ? children : init ? children : null;
};
