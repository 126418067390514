import React, { useState, useEffect, useRef, forwardRef } from 'react';
import {
  Box,
  Typography,
  Button,
  TextField,
  Grow,
  Paper,
  Popper,
  MenuItem,
  MenuList,
  ClickAwayListener,
  CircularProgress,
  colors,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useLocation } from 'react-router-dom';
import {
  BiSearch,
  BiChevronDown,
  BiChevronLeft,
  BiChevronRight,
} from 'react-icons/bi';

import { Header } from '../components/Header';
import { useAxios } from '../hooks/useAxios';
import URIS from '../api';
import { HealthRiskScore } from '../containers/HealthRiskScore';

const initPaginationState = {
  currentPage: 1,
  totalNoOfPages: 1,
};

export function WellnessAnalytics() {
  const classes = useStyles();

  const { search } = useLocation();

  const [open, setOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState({
    locationName: '',
    locationId: '',
  });
  const [{ pattern, debouncedPattern }, setPattern] = useState({
    pattern: '',
    debouncedPattern: '',
  });
  const [{ currentPage, totalNoOfPages }, setPagination] =
    useState(initPaginationState);

  const anchorRef = useRef(null);
  const prevOpen = useRef(open);
  const initRef = useRef(false);

  const {
    myFetch: getLocationList,
    isLoading: locationListLoading,
    responseData: locationList,
  } = useAxios({ listTemplate: true });

  const {
    myFetch: getWellnessList,
    isLoading: wellnessListLoading,
    responseData: wellnessList,
  } = useAxios({ listTemplate: true, refreshWithEveryFetch: false });

  useEffect(() => {
    getLocationList({
      url: URIS.LOCATION_LIST,
      payload: {
        table_config_rows_per_page: '10',
        table_config_page_no: '1',
      },
    }).catch(err => {});
  }, [getLocationList]);

  useEffect(() => {
    if (initRef.current) {
      setPagination(initPaginationState);
    }
  }, [selectedLocation.locationId]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setPattern(({ pattern }) => ({
        pattern,
        debouncedPattern: pattern,
      }));

      if (initRef.current) {
        setPagination(initPaginationState);
      }
    }, 500);

    return () => {
      clearTimeout(timer);
    };
  }, [pattern]);

  useEffect(() => {
    let params = null;

    if (search) {
      const urlSearchParams = new URLSearchParams(search);
      params = Object.fromEntries(urlSearchParams.entries());
    }

    getWellnessList({
      url: URIS.WELLNESS_LIST,
      payload: {
        table_config_rows_per_page: '10',
        table_config_page_no: (currentPage || 1) + '',
        pattern: debouncedPattern,
        location: selectedLocation.locationId,
        ...(params && params.sort === 'risk' && params.orderby === 'desc'
          ? {
              sort: [
                {
                  [params.sort]: params.orderby,
                },
              ],
            }
          : {}),
      },
    })
      .then(data => {
        setPagination(({ currentPage }) => ({
          currentPage: isNaN(+data?.current_page)
            ? currentPage
            : +data.current_page,
          totalNoOfPages: isNaN(+data?.total_pages_count)
            ? currentPage - 1
            : +data.total_pages_count,
        }));
      })
      .catch(err => {
        setPagination(({ currentPage }) => ({
          currentPage,
          totalNoOfPages: currentPage - 1,
        }));
      })
      .finally(() => {
        initRef.current = true;
      });
  }, [
    debouncedPattern,
    selectedLocation.locationId,
    currentPage,
    getWellnessList,
    search,
  ]);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    if (
      typeof event.target.dataset?.locationid === 'string' &&
      typeof event.target.dataset?.branchname === 'string'
    ) {
      setSelectedLocation({
        locationId: event.target.dataset.locationid,
        locationName: event.target.dataset.branchname,
      });

      setOpen(false);
    }
  };

  return (
    <div>
      <Header />
      <Typography variant="h2" className={classes.Topheading}>
        Wellness Analytics
      </Typography>
      <Box className={classes.Topcol}>
        <Box className={classes.Searchcol}>
          <BiSearch size="20" className={classes.Searchicon} />
          <TextField
            id="standard-basic"
            label="Search Employee by name or ID"
            variant="standard"
            style={{ width: '100%', fontFamily: 'poppins' }}
            onChange={e =>
              setPattern(cur => ({
                pattern: e.target.value,
                debouncedPattern: cur.debouncedPattern,
              }))
            }
            value={pattern}
          />
        </Box>
        <Box className={classes.Dropdown}>
          <Box className={classes.Action}>
            {/* <Select options={options} onChange={(values) => this.setValues(values)} placeholder="Action" className={classes.select} /> */}
            <Button
              className={classes.Optionsbtn}
              ref={anchorRef}
              aria-controls={open ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={setOpen.bind(null, prevOpen => !prevOpen)}
              disabled={
                locationListLoading ||
                !Array.isArray(locationList?.response?.response_packages)
              }
            >
              <span style={{ position: 'relative' }}>
                {!locationListLoading &&
                !Array.isArray(locationList?.response?.response_packages)
                  ? 'Fetching list failed'
                  : selectedLocation.locationName || 'Select Office Location'}
                {locationListLoading && (
                  <span
                    style={{
                      display: 'inline-flex',
                      width: '100%',
                      height: '100%',
                      justifyContent: 'center',
                      alignItems: 'center',
                      position: 'absolute',
                      top: 0,
                      left: 0,
                    }}
                  >
                    <CircularProgress size="1rem" />
                  </span>
                )}
              </span>
              &nbsp;
              <BiChevronDown className={classes.Downarrow} />
            </Button>
            <Popper
              open={open}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
              style={{ zIndex: '999999' }}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom' ? 'center top' : 'center bottom',
                  }}
                >
                  <Paper
                    style={{
                      boxShadow: 'none',
                      width: '195px',
                      border: '1px #AEAEAE solid',
                      maxHeight: 220,
                      overflowY: 'auto',
                      borderRadius: '10px',
                    }}
                  >
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={open}
                        id="menu-list-grow"
                        onKeyDown={event => {
                          if (event.key === 'Tab') {
                            event.preventDefault();
                            setOpen(false);
                          }
                        }}
                        onClick={handleClose}
                      >
                        <MenuItem
                          data-locationid=""
                          data-branchname=""
                          style={{
                            color: '#aaa',
                            fontSize: '12px',
                            paddingLeft: '10px',
                            paddingRight: '10px',
                            fontStyle: 'italic',
                          }}
                        >
                          Clear
                        </MenuItem>
                        {Array.isArray(
                          locationList?.response?.response_packages,
                        ) &&
                          locationList?.response?.response_packages.map(
                            item => (
                              <MenuItem
                                key={item?.id}
                                data-locationid={item?.id}
                                data-branchname={item?.branch}
                                style={{
                                  color: '#7087A7',
                                  fontSize: '12px',
                                  paddingLeft: '10px',
                                  paddingRight: '10px',
                                }}
                              >
                                {item?.branch}
                              </MenuItem>
                            ),
                          )}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </Box>
        </Box>

        <Box alignSelf="flex-end" marginLeft="1.5rem">
          {wellnessListLoading && <CircularProgress size="1.5rem" />}
        </Box>
      </Box>

      <Box className={classes.Datatable}>
        <Box className={classes.Throw}>
          <Box className={classes.col1}>Employee ID</Box>
          <Box className={classes.col2}>Name</Box>
          <Box className={classes.col3}>Office Location</Box>
          <Box className={classes.col4}>Total Scans</Box>
          <Box className={classes.col5}>Risk Score</Box>
          <Box className={classes.col6}>Details</Box>
        </Box>
        {!Array.isArray(wellnessList?.response?.response_packages) &&
        wellnessListLoading ? (
          Array.from({ length: 4 }, (_, index) => (
            <Box
              className={classes.Throw}
              key={index}
              style={{
                padding: '1rem',
                justifyContent: 'center',
                alignItems: 'center',
                fontStyle: 'italic',
                color: colors.grey[400],
              }}
            >
              Fetching Data...
            </Box>
          ))
        ) : !Array.isArray(wellnessList?.response?.response_packages) ||
          currentPage > totalNoOfPages ? (
          <Box
            className={classes.Throw}
            style={{
              padding: '1rem',
              justifyContent: 'center',
              alignItems: 'center',
              color: colors.grey[400],
              height: '16rem',
            }}
          >
            No data Found
          </Box>
        ) : (
          wellnessList.response.response_packages.map((employee, index) => (
            <Box
              className={classes.Tdrow}
              key={employee?.client_employee_id || index}
            >
              <Box className={classes.Tdcol1}>
                {employee?.client_employee_id || '-NA-'}
              </Box>
              <Box className={classes.Tdcol2}>{employee?.name || '-NA-'}</Box>
              <Box className={classes.Tdcol3}>
                {employee?.office_location || '-NA-'}
              </Box>
              <Box className={classes.Tdcol4}>
                {isNaN(+employee?.total_scans)
                  ? '-NA-'
                  : +employee?.total_scans}
              </Box>
              <Box className={classes.Midscore}>
                <HealthRiskScore>{employee?.health_risk_score}</HealthRiskScore>
              </Box>
              <Box className={classes.Tdcol6}>
                <Button
                  className={classes.Viewbtn}
                  component={forwardRef((props, ref) => (
                    <Link
                      to={{
                        pathname: `/employee-details/${employee?.id}`,
                        state: 'wa',
                      }}
                      innerRef={ref}
                      {...props}
                    />
                  ))}
                >
                  View <BiChevronRight />
                </Button>
              </Box>
            </Box>
          ))
        )}

        <Box
          display="flex"
          alignItems="center"
          justifyContent="end"
          marginTop={3}
        >
          {wellnessListLoading && <CircularProgress size="1rem" />}

          <Button
            style={{
              fontSize: '0.8rem',
              textTransform: 'none',
              verticalAlign: 'middle',
              marginRight: '1rem',
            }}
            disabled={
              wellnessListLoading ||
              currentPage === 1 ||
              currentPage > totalNoOfPages
            }
            onClick={() => {
              setPagination(({ currentPage, totalNoOfPages }) => ({
                currentPage: currentPage - 1,
                totalNoOfPages,
              }));
            }}
          >
            <BiChevronLeft size={30} />
            <span>Previous</span>
          </Button>

          <Button
            style={{
              fontSize: '0.8rem',
              textTransform: 'none',
              verticalAlign: 'middle',
            }}
            disabled={wellnessListLoading || currentPage >= totalNoOfPages}
            onClick={() => {
              setPagination(({ currentPage, totalNoOfPages }) => ({
                currentPage: currentPage + 1,
                totalNoOfPages,
              }));
            }}
          >
            <span>Next</span>
            <BiChevronRight size={30} />
          </Button>
        </Box>
      </Box>
    </div>
  );
}

const useStyles = makeStyles(() => ({
  leftcol: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    backgroundColor: '#081A51',
    width: '200px',
  },
  Viewbtn: {
    fontFamily: 'poppins',
    fontSize: 14,
    textTransform: 'capitalize',
    color: '#081A51',
  },
  Datatable: {
    width: '84%',
    padding: '2%',
    margin: '10px 2%',
    backgroundColor: '#fff',
    borderRadius: 10,
    boxShadow: '0px 5px 10px #F1F2FA',
  },
  Throw: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px rgba(218,218,218,0.5) solid',
    paddingBottom: 10,
    '@media (max-width:767px)': {
      display:'none'
    }
  },
  Tdrow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px 0',
    alignItems: 'center',
    borderBottom: '1px rgba(218,218,218,0.5) solid',
    '@media (max-width:767px)': {
      flexWrap:'wrap'
    }
  },
  col1: {
    width: '15%',
    fontFamily: 'poppins',
    fontSize: 14,
    color: '#000',
    fontWeight: '600',
    textAlign: 'left',
    '@media (max-width:767px)': {
      width: '85%',
    }
  },
  col2: {
    width: '20%',
    fontFamily: 'poppins',
    fontSize: 14,
    color: '#000',
    fontWeight: '600',
    textAlign: 'left',
    '@media (max-width:767px)': {
      width: '85%',
    }
  },
  col3: {
    width: '20%',
    fontFamily: 'poppins',
    fontSize: 14,
    color: '#000',
    fontWeight: '600',
    textAlign: 'left',
  },
  col4: {
    width: '15%',
    fontFamily: 'poppins',
    fontSize: 14,
    color: '#000',
    fontWeight: '600',
    textAlign: 'left',
  },
  col5: {
    width: '15%',
    fontFamily: 'poppins',
    fontSize: 14,
    color: '#000',
    fontWeight: '600',
    textAlign: 'left',
  },
  col6: {
    width: '15%',
    fontFamily: 'poppins',
    fontSize: 14,
    color: '#000',
    fontWeight: '600',
  },
  Tdcol1: {
    width: '15%',
    fontFamily: 'poppins',
    fontSize: 14,
    color: '#000',
    fontWeight: '400',
    textAlign: 'left',
    '@media (max-width:767px)': {
      width: '85%',
    }
  },
  Tdcol2: {
    width: '20%',
    fontFamily: 'poppins',
    fontSize: 14,
    color: '#000',
    fontWeight: '400',
    textAlign: 'left',
    '@media (max-width:767px)': {
      width: '85%',
    }
  },
  Tdcol3: {
    width: '20%',
    fontFamily: 'poppins',
    fontSize: 14,
    color: '#000',
    fontWeight: '400',
    textAlign: 'left',
    '@media (max-width:767px)': {
      width: '35%',
    }
  },
  Tdcol4: {
    width: '15%',
    fontFamily: 'poppins',
    fontSize: 14,
    color: '#000',
    fontWeight: '400',
    textAlign: 'left',
    '@media (max-width:767px)': {
      width: '15%',
    }
  },
  Midscore: {
    width: '15%',
    fontFamily: 'poppins',
    fontSize: 14,
    color: '#E9AB0D',
    fontWeight: '400',
    textAlign: 'left',
    '@media (max-width:767px)': {
      width: '30%',
    }
  },
  LowScore: {
    width: '15%',
    fontFamily: 'poppins',
    fontSize: 14,
    color: '#65C22C',
    fontWeight: '400',
    textAlign: 'left',
  },
  Higscore: {
    width: '15%',
    fontFamily: 'poppins',
    fontSize: 14,
    color: '#D00C0C',
    fontWeight: '400',
    textAlign: 'left',
  },
  Tdcol6: {
    width: '15%',
    fontFamily: 'poppins',
    fontSize: 14,
    color: '#000',
    fontWeight: '400',
  },
  Action: {
    marginTop: 15,
  },
  Optionsbtn: {
    fontFamily: 'poppins',
    fontSize: 14,
    textTransform: 'capitalize',
    color: '#717171',
    fontWeight: '400',
  },
  Downarrow: {
    fontSize: '20px',
    color: '#017EFA',
  },
  rightcol: {
    marginLeft: '15%',
    width: '85%',
  },
  Topheading: {
    fontFamily: 'poppins',
    fontSize: 22,
    fontWeight: '700',
    textAlign: 'left',
    paddingLeft: '3%',
    marginTop: 20,
  },
  Topcol: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px 3%',
    '@media (max-width:767px)': {
      flexDirection: 'column',
    }
  },
  Searchcol: {
    width: '87%',
    marginRight: 30,
    position: 'relative',
    '& .MuiInput-input': {
      paddingLeft: 25,
    },
    '& .MuiInputLabel-formControl': {
      paddingLeft: 25,
      fontSize: 14,
      fontFamily: 'poppins',
    },
  },
  Searchicon: {
    position: 'absolute',
    left: 0,
    top: 20,
  },
}));
