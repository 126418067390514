import { makeStyles, Box, Typography, colors } from '@material-ui/core';
import { memo } from 'react';

const graphColors = [
  ...Object.values(
    ['#BDE05B', '#E7AE79', '#89C3D6', '#CB6262', '#7D6FE3'].reduce(
      (curValue, color) => ({
        ...curValue,
        [Math.floor(Math.random() * 100)]: color,
      }),
      {},
    ),
  ),
];

export const AgeWiseEmployeeRiskPercentages = memo(
  ({ ageWiseData, loading }) => {
    const classes = useStyles();

    const error = typeof ageWiseData !== 'object' || Array.isArray(ageWiseData);

    // let totalEmployeeStrength = 0;

    // const employeeStrength =
    //   !error &&
    //   Object.values(ageWiseData).map(item => {
    //     const error =
    //       !Array.isArray(item) ||
    //       item.length !== 2 ||
    //       isNaN(+item[0]) ||
    //       isNaN(+item[1]);

    //     if (error) {
    //       return 0;
    //     }

    //     totalEmployeeStrength += +item[0];

    //     return +item[0];
    //   });

    return (
      <Box className={classes.EmployeeGraph}>
        <Typography
          style={{
            fontFamily: 'poppins',
            fontSize: 16,
            textAlign: 'left',
            marginBottom: 10,
          }}
        >
          Employee Age wise Risk Percentages
        </Typography>
        <Box
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Box style={{ fontSize: 12, color: '#717171', width: '45%' }}>
            Age
          </Box>
          <Box style={{ fontSize: 12, color: '#717171' }}>Total Employee</Box>
          <Box style={{ fontSize: 12, color: '#717171', textAlign: 'right' }}>
            High Risk
          </Box>
        </Box>
        {/* <Box className={classes.Agerow}>
          {!error &&
            employeeStrength.map((item, index) => (
              <Box
                key={index}
                className={
                  classes[
                    'row' +
                      (index === 0
                        ? 1
                        : index === employeeStrength.length - 1
                        ? 3
                        : 2)
                  ]
                }
                style={{
                  backgroundColor: graphColors[index],
                  width: `${(item / totalEmployeeStrength) * 100}%`,
                }}
              />
            ))} */}
        {/* <Box className={classes.row1}></Box>
          <Box className={classes.row2}></Box>
          <Box className={classes.row3}></Box>
          <Box className={classes.row4}></Box> */}
        {/* </Box> */}

        {loading ? (
          <Box
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            style={{
              color: colors.grey[600],
              fontStyle: 'italic',
            }}
          >
            Fetching Risk Percentages...
          </Box>
        ) : (
          !error &&
          Object.entries(ageWiseData)
            .sort(([key1], [key2]) =>
              key1.startsWith('<')
                ? -1
                : key2.startsWith('<')
                ? 1
                : key2.startsWith('>')
                ? -1
                : key1.startsWith('>')
                ? 1
                : key1 < key2
                ? -1
                : key1 > key2
                ? 1
                : 0,
            )
            .map(([key, value], index) => {
              const error =
                typeof key !== 'string' ||
                !Array.isArray(value) ||
                value.length !== 2 ||
                isNaN(+value[0]) ||
                isNaN(+value[1]);

              if (error) {
                return null;
              }

              key = key.split(' ')[0].replace('-', ' - ') + ' years old';
              const [employeeStrength, riskPercentage] = value;

              return (
                <Box className={classes.EmployeeValue} key={key}>
                  <Box
                    className={classes.Thcolor2}
                    style={{ backgroundColor: graphColors[index] }}
                  />
                  <Box className={classes.Thcol2}>{key}</Box>
                  <Box className={classes.Thcol3}>{`${employeeStrength} ${
                    employeeStrength > 0 ? 'Employees' : 'Employee'
                  }`}</Box>
                  <Box className={classes.Thcol4}>
                    {(+riskPercentage).toFixed(0)}%
                  </Box>
                </Box>
              );
            })
        )}
      </Box>
    );
  },
);

const useStyles = makeStyles(() => ({
  Agerow: {
    display: 'flex',
    flexDirection: 'row',
  },
  EmployeeValue: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 10,
  },
  Thcolor1: {
    width: 12,
    height: 12,
    backgroundColor: '#BDE05B',
    borderRadius: 40,
    marginRight: 10,
    marginLeft: 5,
  },
  Thcolor2: {
    width: 12,
    height: 12,
    backgroundColor: '#E7AE79',
    borderRadius: 40,
    marginRight: 10,
    marginLeft: 5,
  },
  Thcolor3: {
    width: 12,
    height: 12,
    backgroundColor: '#89C3D6',
    borderRadius: 40,
    marginRight: 10,
    marginLeft: 5,
  },
  Thcolor4: {
    width: 12,
    height: 12,
    backgroundColor: '#CB6262',
    borderRadius: 40,
    marginRight: 10,
    marginLeft: 5,
  },
  Thcol2: {
    fontSize: 14,
    marginRight: 0,
    width: '45%',
  },
  Thcol3: { marginRight: 15, fontSize: 14, color: '#1C1F37', width: '33%' },
  Thcol4: {
    width: 32,
    height: 32,
    backgroundColor: '#DF1010',
    borderRadius: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 12,
    color: '#fff',
  },
  row1: {
    height: '10px',
    marginRight: 2,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
  },
  row2: {
    height: '10px',
    marginRight: 2,
  },
  row3: {
    height: '10px',
    marginRight: 2,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
  },
  EmployeeGraph: {
    width: '55%',
    paddingLeft: '8%',
    '@media (max-width:767px)': {
      width: '96%',
      paddingLeft: '4%',
    },
  },
}));
