import { Fragment } from 'react';
import { Box, colors, makeStyles, Typography } from '@material-ui/core';
import { BiDownArrowAlt, BiUpArrowAlt } from 'react-icons/bi';

export const CardValue = ({ loading = false, value, percentageValue }) => {
  const classes = useStyles();

  return (
    <Box className={classes.Valuecol}>
      <Typography variant="h2" className={classes.Valuetext}>
        {loading ? '--' : (!isNaN(+value) && +value) || '0'}
      </Typography>

      <Box
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        {loading ? (
          '--'
        ) : !isNaN(+percentageValue) && +percentageValue !== 0 ? (
          <Fragment>
            {percentageValue > 0 ? (
              <BiUpArrowAlt color="#30D988" />
            ) : percentageValue < 0 ? (
              <BiDownArrowAlt color="#D9303A" />
            ) : (
              '--'
            )}
            <Typography
              style={{
                fontSize: 10,
                color:
                  percentageValue > 0
                    ? '#30D988'
                    : percentageValue < 0
                    ? '#D9303A'
                    : 'inherit',
              }}
            >
              {Math.abs(+percentageValue) + '%'}
            </Typography>
          </Fragment>
        ) : (
          <span style={{ color: colors.grey[500], fontSize: 10 }}>
            <BiUpArrowAlt color="inherit" />
            0%
          </span>
        )}

        <Typography
          style={{
            fontSize: 11,
            color: 'rgba(28, 31, 55, 0.58)',
            marginLeft: 5,
          }}
        >
          vs last 1 month
        </Typography>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  Valuecol: {
    display: 'flex',
    flexDirection: 'row',
  },
  Valuetext: {
    fontSize: 16,
    fontWeight: '700',
    color: '#1C1F37',
    fontFamily: 'poppins',
  },
}));
