import React, { useEffect } from 'react';
import { Box, Typography, colors } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Header } from '../components/Header';
import { useAxios } from '../hooks/useAxios';
import URIS from '../api';

export function LocationOverview() {
  const classes = useStyles();

  const {
    myFetch: getLocationData,
    isLoading: locationDataLoading,
    responseData: locationData,
  } = useAxios();

  useEffect(() => {
    getLocationData({
      loadingMessage: 'Loading Dashboard Data',
      url: URIS.DASHBOARD_DATA,
      payload: {
        channel_id: ['location_based'],
      },
    }).catch(err => {});
  }, [getLocationData]);

  return (
    <div>
      <Header />
      <Box className={classes.ContenarCol}>
        <Typography variant="h2" className={classes.Topheading}>
          Office Locations Overview
        </Typography>
        <Box className={classes.Datatable}>
          <Box className={classes.Throw}>
            <Box className={classes.col1}>Location</Box>
            <Box className={classes.col2}>Number of Employee</Box>
            <Box className={classes.col3}>Risk Score</Box>
          </Box>

          {!Array.isArray(locationData?.location_based_data) &&
          locationDataLoading ? (
            Array.from({ length: 4 }, (_, index) => (
              <Box
                className={classes.Tdrow}
                key={index}
                style={{
                  padding: '1rem',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontStyle: 'italic',
                  color: colors.grey[400],
                }}
              >
                Fetching Data...
              </Box>
            ))
          ) : !Array.isArray(locationData?.location_based_data) ? (
            <Box
              className={classes.Tdrow}
              style={{
                padding: '1rem',
                justifyContent: 'center',
                alignItems: 'center',
                color: colors.grey[400],
                height: '16rem',
              }}
            >
              No data Found
            </Box>
          ) : (
            locationData.location_based_data.map((location, index) => (
              <Box
                key={location?.location + index}
                className={classes.Tdrow}
                style={{
                  padding: '0.8rem 0',
                  alignItems: 'left',
                  color: colors.grey[400],
                }}
              >
                <Box className={classes.Tdcol1}>
                  {location?.location || '-NA-'}
                </Box>
                <Box className={classes.Tdcol2}>
                  {isNaN(+location?.employee_count)
                    ? '-NA-'
                    : `${location.employee_count} Employee${
                        +location.employee_count > 1 ? 's' : ''
                      }`}
                </Box>
                <Box className={classes.Tdcol6}>
                  <Typography className={classes.Score}>
                    {location?.risk_percentage}%
                  </Typography>
                  <Typography variant="p" className={classes.risktext}>
                    at Risk
                  </Typography>
                </Box>
              </Box>
            ))
          )}
        </Box>
      </Box>
    </div>
  );
}

const useStyles = makeStyles(() => ({
  Topheading: {
    fontFamily: 'poppins',
    fontSize: 22,
    fontWeight: '700',
    textAlign: 'left',
    marginTop: 20,
    marginBottom: 20,
  },
  ContenarCol:{
    padding: '20px 25px',
    '@media (max-width:767px)': {
      padding: '20px 15px',
    }
  },
  Datatable: {
    width: '100%',
    padding: '2%',
    margin: '10px 0',
    backgroundColor: '#fff',
    borderRadius: 10,
    boxShadow: '0px 5px 10px #F1F2FA',
    '@media (max-width:767px)': {
      padding: '2% 4%',
    }
  },
  Tdcol1: {
    fontFamily: 'poppins',
    fontSize: 14,
    color: '#000',
    fontWeight: '400',
    textAlign: 'left',
    width: '40%',
    '@media (max-width:767px)': {
      width:'100%',
      marginBottom:5,
      fontWeight:'600'
    }
  },
  Tdcol2: {
    fontFamily: 'poppins',
    fontSize: 14,
    color: '#000',
    fontWeight: '400',
    textAlign: 'left',
    width: '65%',
    display: 'flex',
    justifyContent: 'flex-start',
  },
  Tdcol6: {
    flexDirection: 'row',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '35%',
  },
  Throw: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px rgba(218,218,218,0.5) solid',
    paddingBottom: 10,
    '@media (max-width:767px)': {
      display:'none'
    }
  },
  Tdrow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px 0',
    alignItems: 'center',
    borderBottom: '1px rgba(218,218,218,0.5) solid',
    '@media (max-width:767px)': {
      flexWrap:'wrap'
    }
  },
  Midscore: {
    width: '15%',
    fontFamily: 'poppins',
    fontSize: 14,
    color: '#E9AB0D',
    fontWeight: '400',
    textAlign: 'left',
  },
  LowScore: {
    width: '15%',
    fontFamily: 'poppins',
    fontSize: 14,
    color: '#65C22C',
    fontWeight: '400',
    textAlign: 'left',
  },
  Higscore: {
    width: '15%',
    fontFamily: 'poppins',
    fontSize: 14,
    color: '#D00C0C',
    fontWeight: '400',
    textAlign: 'left',
  },
  Score: {
    fontSize: '14px',
    color: '#DF0E0E',
    fontWeight: 'bold',
    marginRight: '5px',
    msFlexDirection: 'row',
  },
  risktext: {
    fontSize: '10px',
    color: '#313131',
  },
  col1: {
    fontFamily: 'poppins',
    fontSize: 14,
    color: '#000',
    fontWeight: '600',
    textAlign: 'left',
    width: '40%',
    display: 'flex',
    justifyContent: 'flex-start',
    '@media (max-width:767px)': {
      display:'none'
    }
  },
  col2: {
    fontFamily: 'poppins',
    fontSize: 14,
    color: '#000',
    fontWeight: '600',
    textAlign: 'left',
    width: '40%',
    display: 'flex',
    justifyContent: 'flex-start',
    '@media (max-width:767px)': {
      display:'none'
    }
  },
  col3: {
    fontFamily: 'poppins',
    fontSize: 14,
    color: '#000',
    fontWeight: '600',
    textAlign: 'left',
    width: '20%',
    display: 'flex',
    justifyContent: 'flex-end',
    '@media (max-width:767px)': {
      display:'none'
    }
  },
}));
