export const primaryFonts = [
  'Poppins',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  'Roboto',
  '"Helvetica Neue"',
  'Arial',
  'sans-serif',
  '"Apple Color Emoji"',
  '"Segoe UI Emoji"',
  '"Segoe UI Symbol"',
].join(',');

export const theme = {
  palette: {
    background: {
      default: '#E5E5E5',
    },
  },
  typography: {
    fontFamily: primaryFonts,

    allVariants: {
      fontFamily: primaryFonts,
    },
  },
};
