import { configureStore } from '@reduxjs/toolkit';

import { authReducer } from './slices/auth-slice';
import { uiReducer } from './slices/ui-slice';
import { profileDetailsReducer } from './slices/profile-details-slice';
import { sidebarReducer } from './slices/sidebar-slice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    ui: uiReducer,
    profileDetails: profileDetailsReducer,
    sidebar: sidebarReducer,
  },
});

export { authActions } from './slices/auth-slice';
export { uiActions } from './slices/ui-slice';
export { profileDetailsActions } from './slices/profile-details-slice';
export { sidebarActions } from './slices/sidebar-slice';
export {
  loginUsingSavedCredentials,
  loginUsingEmailAndPassword,
  logout,
} from './action-creators/auth';
