import { useMemo } from 'react';
import { Switch, Redirect } from 'react-router-dom';

import { Login } from './pages/Login';
import { Dashboard } from './pages/Dashboard';
import { WellnessAnalytics } from './pages/WellnessAnalytics';
import { Initialize } from './hoc/Initialize';
import { HandleError } from './hoc/HandleError';
import { SnackBarWrapper } from './hoc/SnackBarWrapper';
import { LoaderWrapper } from './hoc/LoaderWrapper';
import { LoggedOutRoute } from './hoc/LoggedOutRoute';
import { ProtectedRoute } from './hoc/ProtectedRoute';
import { Layout } from './layout';
import { EmployeeDetails } from './pages/EmployeeDetails';
import { LocationOverview } from './pages/LocationOverview';

function App() {
  return (
    <Initialize>
      <LoaderWrapper>
        <SnackBarWrapper>
          <HandleError>
            <Layout>
              <Switch>
                <LoggedOutRoute
                  exact
                  path={useMemo(
                    () => [
                      '/login',
                      ...[
                        'dashboard',
                        'wellness-analytics',
                        'employee-details',
                        'location-overview',
                      ].map(item => `/${item}/login`),
                    ],
                    [],
                  )}
                >
                  <Login />
                </LoggedOutRoute>

                <ProtectedRoute exact path="/dashboard">
                  <Dashboard />
                </ProtectedRoute>

                <ProtectedRoute exact path="/wellness-analytics">
                  <WellnessAnalytics />
                </ProtectedRoute>

                <ProtectedRoute exact path="/employee-details/:employeeId">
                  <EmployeeDetails />
                </ProtectedRoute>

                <ProtectedRoute exact path="/location-overview">
                  <LocationOverview />
                </ProtectedRoute>

                <Redirect from="/" to="/dashboard" />
              </Switch>
            </Layout>
          </HandleError>
        </SnackBarWrapper>
      </LoaderWrapper>
    </Initialize>
  );
}

export default App;
