import { memo, useEffect, useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  colors,
  makeStyles,
} from '@material-ui/core';
import { BiChevronLeft, BiChevronRight } from 'react-icons/bi';
import isValid from 'date-fns/isValid';
import format from 'date-fns/format';

import { useAxios } from '../../hooks/useAxios';
import URIS from '../../api';
import { HealthRiskScore } from '../HealthRiskScore';

export const ScanHistory = memo(({ userId }) => {
  const classes = useStyles();

  const [{ currentPage, totalNoOfPages }, setPagination] = useState({
    currentPage: 1,
    totalNoOfPages: 1,
  });

  const {
    myFetch: getScanHistory,
    isLoading: scanHistoryLoading,
    responseData: scanHistory,
  } = useAxios({ listTemplate: true, refreshWithEveryFetch: false });

  useEffect(() => {
    getScanHistory({
      url: URIS.SCAN_HISTORY,
      payload: {
        table_config_rows_per_page: '5',
        table_config_page_no: (currentPage || 1) + '',
        user_id: userId,
      },
    })
      .then(data => {
        setPagination(({ currentPage }) => ({
          currentPage: isNaN(+data?.current_page)
            ? currentPage
            : +data.current_page,
          totalNoOfPages: isNaN(+data?.total_pages_count)
            ? currentPage - 1
            : +data.total_pages_count,
        }));
      })
      .catch(err => {
        setPagination(({ currentPage }) => ({
          currentPage,
          totalNoOfPages: currentPage - 1,
        }));
      });
  }, [userId, currentPage, getScanHistory]);

  return (
    <Box className={classes.Datatable}>
      <Box className={classes.Throw}>
        <Box className={classes.col1}>Scan ID</Box>
        <Box className={classes.col2}>Scan Date</Box>
        <Box className={classes.col3}>Scan Time</Box>
        <Box className={classes.col5}>Risk Score</Box>
      </Box>
      {!Array.isArray(scanHistory?.response?.response_packages) &&
      scanHistoryLoading ? (
        Array.from({ length: 3 }, (_, index) => (
          <Box
            className={classes.Tdrow}
            key={index}
            style={{
              padding: '1rem',
              justifyContent: 'center',
              alignItems: 'center',
              fontStyle: 'italic',
              color: colors.grey[400],
            }}
          >
            Fetching Data...
          </Box>
        ))
      ) : !Array.isArray(scanHistory?.response?.response_packages) ||
        currentPage > totalNoOfPages ? (
        <Box
          className={classes.Tdrow}
          style={{
            padding: '1rem',
            justifyContent: 'center',
            alignItems: 'center',
            color: colors.grey[400],
            height: '8rem',
          }}
        >
          No data Found
        </Box>
      ) : (
        scanHistory.response.response_packages.map((scan, index) => (
          <Box className={classes.Tdrow} key={scan?.id || index}>
            <Box className={classes.Tdcol1}>{scan?.id || '-NA-'}</Box>
            <Box className={classes.Tdcol2}>
              {isValid(new Date(scan?.created_at))
                ? format(new Date(scan?.created_at), 'dd-MM-yyyy')
                : '-NA-'}
            </Box>
            <Box className={classes.Tdcol3}>
              {isValid(new Date(scan?.created_at))
                ? format(new Date(scan?.created_at), 'hh:mm aaa')
                : '-NA-'}
            </Box>
            <Box className={classes.Midscore}>
              <HealthRiskScore>{scan?.health_risk_score}</HealthRiskScore>
            </Box>
          </Box>
        ))
      )}

      <Box
        display="flex"
        alignItems="center"
        justifyContent="end"
        marginTop={3}
      >
        {scanHistoryLoading && <CircularProgress size="1rem" />}

        <Button
          style={{
            fontSize: '0.8rem',
            textTransform: 'none',
            verticalAlign: 'middle',
            marginRight: '1rem',
          }}
          disabled={
            scanHistoryLoading ||
            currentPage === 1 ||
            currentPage > totalNoOfPages
          }
          onClick={() => {
            setPagination(({ currentPage, totalNoOfPages }) => ({
              currentPage: currentPage - 1,
              totalNoOfPages,
            }));
          }}
        >
          <BiChevronLeft size={30} />
          <span>Previous</span>
        </Button>

        <Button
          style={{
            fontSize: '0.8rem',
            textTransform: 'none',
            verticalAlign: 'middle',
          }}
          disabled={scanHistoryLoading || currentPage >= totalNoOfPages}
          onClick={() => {
            setPagination(({ currentPage, totalNoOfPages }) => ({
              currentPage: currentPage + 1,
              totalNoOfPages,
            }));
          }}
        >
          <span>Next</span>
          <BiChevronRight size={30} />
        </Button>
      </Box>
    </Box>
  );
});

const useStyles = makeStyles(() => ({
  Datatable: {
    width: '96%',
    padding: '2%',
    margin: '10px 0',
    backgroundColor: '#fff',
    borderRadius: 10,
    boxShadow: '0px 5px 10px #F1F2FA',
    '@media (max-width:767px)': {
      width: '100%',
    }
  },
  Throw: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px rgba(218,218,218,0.5) solid',
    paddingBottom: 10,
    '@media (max-width:767px)': {
      display:'none'
      }
  },
  Tdrow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px 0',
    alignItems: 'center',
    borderBottom: '1px rgba(218,218,218,0.5) solid',
    '@media (max-width:767px)': {
      flexWrap:'wrap'
    }
  },
  col1: {
    width: '50%',
    fontFamily: 'poppins',
    fontSize: 14,
    color: '#000',
    fontWeight: '600',
    textAlign: 'left',
  },
  col2: {
    width: '20%',
    fontFamily: 'poppins',
    fontSize: 14,
    color: '#000',
    fontWeight: '600',
    textAlign: 'left',
  },
  col3: {
    width: '20%',
    fontFamily: 'poppins',
    fontSize: 14,
    color: '#000',
    fontWeight: '600',
    textAlign: 'left',
  },
  col5: {
    width: '15%',
    fontFamily: 'poppins',
    fontSize: 14,
    color: '#000',
    fontWeight: '600',
    textAlign: 'left',
  },
  col6: {
    width: '15%',
    fontFamily: 'poppins',
    fontSize: 14,
    color: '#000',
    fontWeight: '600',
  },
  Tdcol1: {
    width: '50%',
    fontFamily: 'poppins',
    fontSize: 14,
    color: '#000',
    fontWeight: '400',
    textAlign: 'left',
    '@media (max-width:767px)': {
      width: '98%',
      marginBottom:10,
      fontWeight: '600',
    }
  },
  Tdcol2: {
    width: '20%',
    fontFamily: 'poppins',
    fontSize: 14,
    color: '#000',
    fontWeight: '400',
    textAlign: 'left',
    '@media (max-width:767px)': {
      width: '40%',
    }
  },
  Tdcol3: {
    width: '20%',
    fontFamily: 'poppins',
    fontSize: 14,
    color: '#000',
    fontWeight: '400',
    textAlign: 'left',
    '@media (max-width:767px)': {
      width: '40%',
    }
  },
  Tdcol4: {
    width: '15%',
    fontFamily: 'poppins',
    fontSize: 14,
    color: '#000',
    fontWeight: '400',
    textAlign: 'left',
  },
  Midscore: {
    width: '15%',
    fontFamily: 'poppins',
    fontSize: 14,
    // color: '#E9AB0D',
    fontWeight: '400',
    textAlign: 'left',
  },
}));
