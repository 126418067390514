import { useRef } from 'react';
import { Box, makeStyles, Typography } from '@material-ui/core';
import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

export const DoughnutChart = ({ low, mid, high }) => {
  const classes = useStyles();

  const doughnutRef = useRef(null);

  return (
    <Box className={classes.Doughnutchart} style={{ transform: 'scale(65%)' }}>
      {/* <Box className={classes.Low}>
        <Typography style={{ fontFamily: 'poppins', fontWeight: '700' }}>
          68%
        </Typography>
      </Box>
      <Box className={classes.Mid}>
        <Typography style={{ fontFamily: 'poppins', fontWeight: '700' }}>
          38%
        </Typography>
      </Box>
      <Box className={classes.High}>
        <Typography style={{ fontFamily: 'poppins', fontWeight: '700' }}>
          12%
        </Typography>
      </Box> */}
      <Typography
        style={{
          position: 'absolute',
          left: '50%',
          top: '45%',
          fontSize: 26,
          fontWeight: '500',
          transform: 'translate(-50%, -50%)',
        }}
      >
        Risks
      </Typography>

      <Doughnut
        height="100%"
        ref={doughnutRef}
        data={{
          datasets: [
            {
              label: 'Risks',
              data: [low, mid, high],
              backgroundColor: ['#BDE05B', '#F2D56E', '#CB6262'],
              borderColor: ['#fff', '#fff', '#fff', '#fff', '#fff'],
              borderWidth: 3,
              weight: 5,
            },
          ],
          labels: ['Low', 'Mid', 'High'],
        }}
        options={{
          radius: '90%',
          cutout: '65%',
          rotation: -60,
          layout: {
            padding: {
              top: 27,
            },
          },
          plugins: {
            tooltip: {
              enabled: false,
            },
            legend: {
              position: 'bottom',
              fullSize: false,
              labels: {
                pointStyle: 'circle',
                usePointStyle: true,
                padding: 30,
                color: '#000',
                font: {
                  size: 18,
                  weight: '500',
                },
              },
              onLeave: e => {
                e.native.target.style.cursor = 'default';
              },
              onHover: e => {
                e.native.target.style.cursor = 'pointer';
              },
            },
            datalabels: {
              display: true,
              align: 'end',
              offset: -24,
              color: '#000',
              borderRadius: 1000,
              backgroundColor: '#fff',
              borderColor: '#0001',
              borderWidth: 1,
              anchor: 'end',
              clip: false,
              font: {
                size: 24,
                weight: '900',
              },
              padding: { top: 18, bottom: 18, right: 8, left: 8 },
              formatter: value => {
                if (!+value) {
                  return null;
                }
                return (+value).toFixed(0) + '%';
              },
            },
          },
        }}
        plugins={[ChartDataLabels]}
      />
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  Low: {
    width: 50,
    height: 50,
    backgroundColor: '#fff',
    borderRadius: '50%',
    boxShadow: '2px 2px 10px 1px rgba(0,0,0,0.3)',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    left: '5%',
    top: '25%',
  },

  Mid: {
    width: 50,
    height: 50,
    backgroundColor: '#fff',
    borderRadius: '50%',
    boxShadow: '2px 2px 10px 1px rgba(0,0,0,0.3)',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    left: '85%',
    top: '35%',
  },

  High: {
    width: 50,
    height: 50,
    backgroundColor: '#fff',
    borderRadius: '50%',
    boxShadow: '2px 2px 10px 1px rgba(0,0,0,0.3)',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    left: '65%',
    top: '82%',
  },

  Doughnutchart: {
    width: '105%',
    height: '120%',
    marginLeft: '-2.5%',
    marginTop: '-7%',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));
