import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { createTheme, CssBaseline, ThemeProvider } from '@material-ui/core';
import { Provider } from 'react-redux';
import { defaults } from 'react-chartjs-2';

import App from './App';
import { theme } from './theme';
import { GlobalStyles } from './theme/GlobalStyles';
import { store } from './store';

defaults.font.family = '"Poppins", sans-serif';

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={createTheme(theme)}>
        <CssBaseline />
        <GlobalStyles />
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  </StrictMode>,
  document.getElementById('root'),
);
