import { colors } from '@material-ui/core';

export const HealthRiskScore = ({ children }) => {
  return isNaN(+children) || (!children && typeof children !== 'number') ? (
    <span style={{ color: colors.grey[600] }}>-NA-</span>
  ) : (
    <span
      style={{
        color:
          +children <= 100 / 3
            ? '#65C22C'
            : +children <= 200 / 3
            ? '#E9AB0D'
            : '#D00C0C',
      }}
    >
      {children}
    </span>
  );
};
