import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { LoginForm } from '../containers/LoginForm';
import Background from '../images/login-bg.png';
import logo from '../images/CarePlix_HRes.png';

export function Login() {
  const classes = useStyles();

  return (
    <div
      style={{
        backgroundColor: '#fff',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box className={classes.Login}>
        <Box
          className={classes.LoginImg}
          style={{
            backgroundImage: `url(${Background})`,
            backgroundPosition: 'center',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
        >
          {/* <img src={loginbg} alt="login-bg" /> */}
        </Box>
        <Box className={classes.LoginLeft}>
          <Box className={classes.loginform}>
            <h2 className={classes.toptext}>Login</h2>
            <LoginForm />
          </Box>
          <Box style={{ paddingBottom: 50 }}>
            <Typography variant="p" className={classes.BottomText}>
              Powered by
            </Typography>
            <Box style={{ textAlign: 'center' }}>
              <img src={logo} alt="login-bg" />
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
}

const useStyles = makeStyles(() => ({
  Login: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    backgroundColor: '#fff',
    '@media (max-width:767px)': {
      flexDirection: 'column',
    },
  },
  BottomText: {
    textAlign: 'center',
    flexDirection: 'row',
    justifyContent: 'center',
    display: 'flex',
    '@media (max-width:767px)': {
      marginTop: '45px',
    },
  },
  LoginLeft: {
    width: '32%',
    paddingTop: '50px',
    paddingLeft: '5%',
    paddingRight: '3%',
    textAlign: 'left',
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'space-between',
    '& p': {
      fontSize: '16px',
      color: '#AEAEAE',
    },
    '@media (max-width:767px)': {
      width: '100%',
    },
  },
  LoginImg: {
    width: '68%',
    height: '100vh',
    '& img': {
      width: '100%',
    },
    '@media (max-width:767px)': {
      width: '100%',
      height: '35vh',
    },
  },
  toptext: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '30px',
    color: '#00206C',
    marginTop: '100px',
    '@media (max-width:767px)': {
      marginTop: '0px',
    },
  },
  input: {
    border: 'none',
    borderRadius: '10px',
    height: '54px',
    width: '100%',
    marginBottom: '20px',
  },
  loginform: {
    width: '94%',
    '@media (max-width:767px)': {
      width: '98%',
    },
    '& .MuiInput-underline:before': {
      display: 'none',
    },
    '& .MuiInput-underline:after': {
      display: 'none',
    },
    '& .MuiInput-formControl': {
      height: '50px',
    },
    '& .MuiInputBase-input': {
      height: '50px',
      borderRadius: '10px',
      background: '#F5F5F5',
      border: '1px solid #D6D6D6',
      padding: '0 15px',
    },
    '& .MuiInput-input:focus': {
      border: '1px #7087A7 solid',
      boxShadow: '2px 2px 10px 1px rgba(0,0,0,0.3)',
    },
  },
  loginbtn: {
    background: '#081A51',
    padding: '0 40px',
    width: '100%',
    height: '60px',
    fontSize: '20px',
    borderRadius: '10px',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    boxShadow: '2px 2px 10px 1px rgba(0,0,0,0.3)',
    color: '#fff',
    marginTop: '50px',
    '&:hover': {
      background: '#000',
    },
  },
  rightcontent: {
    width: '80%',
    padding: '0 10%',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    flexDirection: 'column',
    left: '0px',
    top: '0px',
    bottom: '0px',
  },
  rightheading: {
    color: '#fff',
    margin: '0px',
  },
  righttext: {
    textAlign: 'left',
    color: '#fff',
  },
}));
