import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userDetails: null,
};

const profileDetailsSlice = createSlice({
  name: 'profile-details',
  initialState,
  reducers: {
    setUserDetails(state, { payload }) {
      state.userDetails = payload;
    },
    reset(state) {
      state.userDetails = null;
    },
  },
});

export const {
  actions: profileDetailsActions,
  reducer: profileDetailsReducer,
} = profileDetailsSlice;
