import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

import SnackBarAlert from '../components/SnackBarAlert';
import { useSnackbar } from '../hooks/useSnackbar';

export const SnackBarWrapper = ({ children }) => {
  const snackBarState = useSelector((state) => state.ui.snackBarState);

  const { closeSnackbar } = useSnackbar();

  return (
    <Fragment>
      <SnackBarAlert
        message={snackBarState.message}
        type={snackBarState.type}
        duration={snackBarState.duration}
        closeSnackBar={closeSnackbar}
      />
      {children}
    </Fragment>
  );
};
