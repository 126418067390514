const URIS = {
  SIGN_IN: '/auth/operator/sign-in',
  LOCATION_LIST: '/users/locations/list',
  WELLNESS_LIST: '/users/wellness/list',
  DASHBOARD_DATA: '/managers/dashboard',
  SCAN_HISTORY: '/managers/user/scan_history',
  EMPLOYEE_DETAILS: '/managers/dashboard_user',
};

let AVOID_ERROR_CHECKS = [];

const setAvoidErrorChecks = ({ url }) => {
  if (AVOID_ERROR_CHECKS.includes(url)) return;

  AVOID_ERROR_CHECKS.push(url);
};

const removeURLfromAvoidErrorChecks = ({ url }) => {
  AVOID_ERROR_CHECKS = AVOID_ERROR_CHECKS.filter(item => item !== url);
};

export {
  AVOID_ERROR_CHECKS,
  setAvoidErrorChecks,
  removeURLfromAvoidErrorChecks,
};

export default URIS;
