import { createSlice } from '@reduxjs/toolkit';

import { SIDEBAR } from '../../global-constants';

const initialState = {
  open:
    document.body.getBoundingClientRect().width > 812
      ? localStorage.getItem(SIDEBAR) !== 'closed'
      : localStorage.getItem(SIDEBAR) === 'open',
};

const sidebarSlice = createSlice({
  name: 'sidebar',
  initialState,
  reducers: {
    openSidebar(state) {
      state.open = true;
    },
    closeSidebar(state) {
      state.open = false;
    },
  },
});

export const { actions: sidebarActions, reducer: sidebarReducer } =
  sidebarSlice;
