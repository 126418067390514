import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

import { Loader } from '../components/Loader';

export const LoaderWrapper = ({ children }) => {
  const { networkFetching, loadingMessage } = useSelector(state => state.ui);

  return (
    <Fragment>
      <Loader open={networkFetching} loadMessage={loadingMessage} />
      {children}
    </Fragment>
  );
};
