import React from 'react';

import { CircularProgress, Dialog, makeStyles } from '@material-ui/core';

export const Loader = ({ open = false, loadMessage = '' }) => {
  const classes = useStyles();

  return (
    <Dialog open={open}>
      <div className={classes.Loader}>
        <CircularProgress />{' '}
        {!!loadMessage && (
          <span className={classes.LoaderMessage}>{loadMessage}...</span>
        )}
      </div>
    </Dialog>
  );
};

const useStyles = makeStyles(theme => ({
  Loader: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(4, 6, 4, 4),
  },
  LoaderMessage: {
    marginLeft: theme.spacing(2),
    fontSize: theme.typography.pxToRem(16),
  },
}));
