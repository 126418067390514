import { Box, colors, makeStyles } from '@material-ui/core';
import { Line } from 'react-chartjs-2';
import sub from 'date-fns/sub';
import parse from 'date-fns/parse';
import isValid from 'date-fns/isValid';
import format from 'date-fns/format';

const placeHolderLabels = Array.from({ length: 13 }, (_, index) =>
  sub(new Date(), {
    months: index,
  }),
);

const placeHolderData = Array.from({ length: 13 }, _ => 0);

const generateDatasetStyling = color => ({
  borderColor: color,
  borderWidth: 2,
  borderCapStyle: 'round',
  pointRadius: 5,
  pointBorderWidth: 0,
  pointBackgroundColor: color,
  pointHoverBackgroundColor: '#fff',
  pointBorderColor: color,
  pointHoverRadius: 8,
  pointHoverBorderWidth: 3,
});

export const RiskLineChart = ({ riskGraphData }) => {
  const classes = useStyles();

  const riskGraphDataLabels = [];
  // const totalRisksData = [];
  const lowRisksData = [];
  const highRisksData = [];
  const midRisksData = [];

  if (Array.isArray(riskGraphData)) {
    riskGraphData.forEach(item => {
      const parsedDate = parse(item?.month_year, 'yy-MMM', new Date());

      if (
        isValid(parsedDate) &&
        !isNaN(+item?.total) &&
        !isNaN(+item?.low_risk) &&
        !isNaN(+item?.high_risk) &&
        !isNaN(+item?.mid_risk)
      ) {
        riskGraphDataLabels.push(parsedDate);
        // totalRisksData.push(+item.total);
        lowRisksData.push(+item.low_risk);
        highRisksData.push(+item.high_risk);
        midRisksData.push(+item.mid_risk);
      }
    });
  }

  return (
    <Box className={classes.Linechart}>
      <Line
        height={100}
        data={{
          labels:
            riskGraphDataLabels.length <= 0
              ? placeHolderLabels
              : riskGraphDataLabels.reverse(),
          datasets: [
            {
              label: 'Low Risks',
              data:
                lowRisksData.length <= 0
                  ? placeHolderData
                  : lowRisksData.reverse(),

              ...generateDatasetStyling('#3fc210'),
            },
            {
              label: 'Mid Risks',
              data:
                midRisksData.length <= 0
                  ? placeHolderData
                  : midRisksData.reverse(),

              ...generateDatasetStyling('#e7ae1c'),
            },
            {
              label: 'High Risks',
              data:
                highRisksData.length <= 0
                  ? placeHolderData
                  : highRisksData.reverse(),

              ...generateDatasetStyling('#d12c2c'),
            },
            // {
            //   label: 'Total Risks',
            //   data:
            //     totalRisksData.length <= 0
            //       ? placeHolderData
            //       : totalRisksData.reverse(),

            //   ...generateDatasetStyling('#017efa'),
            // },
          ],
        }}
        options={{
          responsive: true,
          maintainAspectRatio: true,
          hover: {
            intersect: false,
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              mode: 'nearest',
              intersect: false,
              displayColors: false,
              caretSize: 0,
              caretPadding: 20,
              titleColor: '#081A51',
              titleFont: {
                weight: '700',
                size: 13,
                lineHeight: 1,
              },
              titleMarginBottom: 6,
              bodyColor: '#aaa',
              bodyFont: {
                weight: '400',
                size: 12,
              },
              cornerRadius: 10,
              padding: {
                bottom: 12,
                top: 16,
                left: 16,
                right: 16,
              },
              backgroundColor: '#fff',
              callbacks: {
                title: ([{ label }]) => format(new Date(label), 'MMMM yyyy'),
                label: ({ parsed, dataset }) =>
                  `${dataset.label} : ${parsed.y} scan${
                    parsed.y > 1 ? 's' : ''
                  }`,
                labelTextColor: ({ dataset }) => dataset.borderColor,
              },
            },
          },
          scales: {
            y: {
              beginAtZero: true,
              grid: {
                color: 'transparent',
                borderColor: 'transparent',
              },
              ticks: {
                color: colors.grey[500],
                padding: 20,
                autoSkip: true,
                autoSkipPadding: 20,
                font: {
                  size: 11,
                },
              },
            },
            x: {
              beginAtZero: true,
              grid: {
                color: 'transparent',
                borderColor: 'transparent',
              },
              ticks: {
                color: colors.grey[500],
                padding: 10,
                font: {
                  size: 11,
                },
                autoSkip: true,
                autoSkipPadding: 10,
                maxRotation: 0,
                callback: function (value, index) {
                  return format(new Date(this.getLabelForValue(index)), 'MMM');
                },
              },
            },
          },
        }}
      />
    </Box>
  );
};

const useStyles = makeStyles(() => ({
  Linechart: {
    marginTop: 20,
    marginLeft: -10,
  },
}));
