import { createStyles, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() =>
  createStyles({
    '@global': {
      html: {
        height: '100%',
        width: '100%',
      },
      body: {
        boxSizing: 'border-box',
      },
      '*, *::before, *::after': {
        boxSizing: 'inherit',
      },
    },
  }),
);

export const GlobalStyles = () => {
  useStyles();

  return null;
};
