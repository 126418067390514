import React, {
  forwardRef,
  Fragment,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Box, Button, Grid, Typography, colors } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BiChevronRight } from 'react-icons/bi';
import { Link } from 'react-router-dom';
import format from 'date-fns/format';
import isValid from 'date-fns/isValid';

import { Header } from '../components/Header';
import { useAxios } from '../hooks/useAxios';
import URIS from '../api';
import { CardValue } from '../containers/CardValue';
import { AgeWiseEmployeeRiskPercentages } from '../containers/AgeWiseEmployeeRiskPercentages';
import { DoughnutChart } from '../containers/DoughnutChart';
import { RiskLineChart } from '../containers/RiskLineChart';
import { HealthRiskScore } from '../containers/HealthRiskScore';

import people from '../images/people.png';
import office from '../images/office.png';
import risk from '../images/risk.png';
import ppg from '../images/ppg.png';

const fetchingData = (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    marginTop={3}
    color={colors.grey[600]}
    fontStyle="italic"
  >
    Fetching Data...
  </Box>
);

const noDataFound = (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    marginTop={3}
    color={colors.grey[500]}
  >
    No Data Found
  </Box>
);

export function Dashboard() {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const anchorRef = useRef(null);
  const prevOpen = useRef(open);

  const {
    myFetch: getDashboardData,
    isLoading: dashboardDataLoading,
    responseData: dashboardData,
  } = useAxios();

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    getDashboardData({
      loadingMessage: 'Loading Dashboard Data',
      url: URIS.DASHBOARD_DATA,
      payload: {
        channel_id: [
          'risk_graph',
          'cards',
          'risk_employee',
          'location_based',
          'agewise',
          'overall_risk',
        ],
      },
    }).catch(err => {});
  }, [getDashboardData]);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  // const handleClose = event => {
  //   if (anchorRef.current && anchorRef.current.contains(event.target)) {
  //     return;
  //   }

  //   setOpen(false);
  // };

  // const handleListKeyDown = event => {
  //   if (event.key === 'Tab') {
  //     event.preventDefault();
  //     setOpen(false);
  //   }
  // };

  return (
    <div style={{ backgroundColor: '#E5E5E5' }}>
      <Header />
      <Box sx={{ flexGrow: 1, padding: '0 3%' }}>
        <Grid container spacing={2}>
          <Grid className={classes.Leftpart}>
            <Typography variant="h2" className={classes.Topheading}>
              Dashboard
            </Typography>
            <Typography variant="h5" className={classes.Subheading}>
              Welcome back!
            </Typography>
            <Box className={classes.contentTable}>
              <Box className={classes.Topcol}>
                <Typography variant="h4" className={classes.TitleText}>
                  Top 5 High Risk Employees
                </Typography>
                <Button
                  className={classes.Viewbtn}
                  component={forwardRef((props, ref) => (
                    <Link
                      to={{
                        pathname: `/wellness-analytics`,
                        search: '?sort=risk&orderby=desc',
                      }}
                      innerRef={ref}
                      {...props}
                    />
                  ))}
                >
                  View More{' '}
                  <BiChevronRight size="18" style={{ marginRight: -3 }} />
                </Button>
              </Box>
              <Box className={classes.tablecol}>
                {dashboardDataLoading
                  ? fetchingData
                  : !Array.isArray(dashboardData?.risk_employee_data)
                  ? noDataFound
                  : dashboardData.risk_employee_data
                      .slice(0, 5)
                      .map((employee, index) => (
                        <Box
                          className={classes.Tdrow}
                          key={employee?.client_employee_id || index}
                        >
                          <Box className={classes.Tdcol1}>
                            <Link
                              className={classes.Linktext}
                              to={{
                                pathname: `/employee-details/${employee?.user_id}`,
                                state: 'wa',
                              }}
                            >
                              {employee?.name || '-NA-'}
                            </Link>
                          </Box>
                          <Box className={classes.Tdcol2}>
                            {isValid(new Date(employee?.last_scan_date))
                              ? format(
                                  new Date(employee?.last_scan_date),
                                  'd MMM, hh:mmaaa',
                                )
                              : '-NA-'}
                          </Box>
                          <Box className={classes.Tdcol3}>
                            <HealthRiskScore>
                              {employee?.health_risk_score}
                            </HealthRiskScore>
                          </Box>
                        </Box>
                      ))}
              </Box>
            </Box>
            <Box className={classes.contentTable}>
              <Box className={classes.Topcol}>
                <Typography variant="h4" className={classes.TitleText}>
                  Office Locations Overview
                </Typography>
                {!dashboardDataLoading &&
                  Array.isArray(dashboardData?.location_based_data) &&
                  dashboardData.location_based_data.length > 5 && (
                    <Button
                      className={classes.Viewbtn}
                      component={forwardRef((props, ref) => (
                        <Link
                          to={{
                            pathname: '/location-overview',
                          }}
                          innerRef={ref}
                          {...props}
                        />
                      ))}
                    >
                      View More{' '}
                      <BiChevronRight size="18" style={{ marginRight: -3 }} />
                    </Button>
                  )}
              </Box>
              <Box className={classes.tablecol}>
                {dashboardDataLoading
                  ? fetchingData
                  : !Array.isArray(dashboardData?.location_based_data)
                  ? noDataFound
                  : dashboardData?.location_based_data
                      .slice(0, 5)
                      .map((locationData, index) => (
                        <Box
                          className={classes.Tdrow}
                          key={locationData?.location || index}
                        >
                          <Box className={classes.Tdcol4}>
                            {locationData?.location || '-NA-'}
                          </Box>
                          <Box className={classes.Tdcol5}>
                            {isNaN(+locationData?.employee_count)
                              ? '-NA-'
                              : `${locationData.employee_count} ${
                                  locationData?.employee_count > 0
                                    ? 'Employees'
                                    : 'Employee'
                                }`}
                          </Box>
                          <Box className={classes.Tdcol6}>
                            {!isNaN(+locationData?.risk_percentage) ? (
                              <Fragment>
                                <Typography className={classes.Score}>
                                  {locationData.risk_percentage}%
                                </Typography>
                                <Typography
                                  variant="p"
                                  className={classes.risktext}
                                >
                                  at Risk
                                </Typography>
                              </Fragment>
                            ) : (
                              '-NA-'
                            )}
                          </Box>
                        </Box>
                      ))}
              </Box>
            </Box>
          </Grid>
          <Grid className={classes.Rightpart}>
            <Box className={classes.Topright}>
              <Typography variant="h4" className={classes.Rightheading}>
                Overall Summary
              </Typography>
              <Box className={classes.Action}>
                {/* <Select options={options} onChange={(values) => this.setValues(values)} placeholder="Action" className={classes.select} /> */}
                <Button
                  className={classes.Optionsbtn}
                  ref={anchorRef}
                  aria-controls={open ? 'menu-list-grow' : undefined}
                  aria-haspopup="true"
                  onClick={handleToggle}
                >
                  Monthly
                  {/* <BiChevronDown className={classes.Downarrow} /> */}
                </Button>
                {/* <Popper
                  open={open}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                  style={{ zIndex: '999999' }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === 'bottom'
                            ? 'center top'
                            : 'center bottom',
                      }}
                    >
                      <Paper
                        style={{
                          boxShadow: 'none',
                          width: '110px',
                          border: '1px #AEAEAE solid',
                          borderRadius: '10px',
                        }}
                      >
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList
                            autoFocusItem={open}
                            id="menu-list-grow"
                            onKeyDown={handleListKeyDown}
                          >
                            <MenuItem
                              onClick={handleClose}
                              style={{
                                color: '#7087A7',
                                fontSize: '12px',
                                paddingLeft: '10px',
                                paddingRight: '10px',
                              }}
                            >
                              Save
                            </MenuItem>
                            <MenuItem
                              onClick={handleClose}
                              style={{
                                color: '#7087A7',
                                fontSize: '12px',
                                paddingLeft: '10px',
                                paddingRight: '10px',
                              }}
                            >
                              {/* <Link style={{ color: '#7087A7' }}>Close</Link> 
                            </MenuItem>
                            <MenuItem
                              onClick={handleClose}
                              style={{
                                color: '#7087A7',
                                fontSize: '12px',
                                paddingLeft: '10px',
                                paddingRight: '10px',
                              }}
                            >
                              Print Care Plan
                            </MenuItem>
                            <MenuItem
                              onClick={handleClose}
                              style={{
                                color: '#7087A7',
                                fontSize: '12px',
                                paddingLeft: '10px',
                                paddingRight: '10px',
                              }}
                            >
                              Unfinalize
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper> */}
              </Box>
            </Box>
            <Box className={classes.EmployeeSummary}>
              <Box className={classes.Summarycol}>
                <Box className={classes.IconCol}>
                  <Box className={classes.Summaryicon}>
                    <img src={people} alt="people" />
                  </Box>
                  <Typography variant="h5" className={classes.icontext}>
                    Total Employees
                  </Typography>
                </Box>
                <CardValue
                  loading={dashboardDataLoading}
                  value={
                    Array.isArray(dashboardData?.cards_data?.total_employees) &&
                    dashboardData.cards_data.total_employees[0]
                  }
                  percentageValue={
                    Array.isArray(dashboardData?.cards_data?.total_employees) &&
                    dashboardData.cards_data.total_employees[1]
                  }
                />
              </Box>

              <Box className={classes.Summarycol}>
                <Box className={classes.IconCol}>
                  <Box className={classes.Summaryicon2}>
                    <img src={office} alt="office" />
                  </Box>
                  <Typography variant="h5" className={classes.icontext}>
                    Total Offices
                  </Typography>
                </Box>
                <CardValue
                  loading={dashboardDataLoading}
                  value={
                    Array.isArray(dashboardData?.cards_data?.total_offices) &&
                    dashboardData.cards_data.total_offices[0]
                  }
                  percentageValue={
                    Array.isArray(dashboardData?.cards_data?.total_offices) &&
                    dashboardData.cards_data.total_offices[1]
                  }
                />
              </Box>

              <Box className={classes.Summarycol}>
                <Box className={classes.IconCol}>
                  <Box className={classes.Summaryicon3}>
                    <img src={risk} alt="risk" />
                  </Box>
                  <Typography variant="h5" className={classes.icontext}>
                    Employees at High Risk
                  </Typography>
                </Box>
                <CardValue
                  loading={dashboardDataLoading}
                  value={
                    Array.isArray(
                      dashboardData?.cards_data?.employees_high_risk,
                    ) && dashboardData.cards_data.employees_high_risk[0]
                  }
                  percentageValue={
                    Array.isArray(
                      dashboardData?.cards_data?.employees_high_risk,
                    ) && dashboardData.cards_data.employees_high_risk[1]
                  }
                />
              </Box>

              <Box className={classes.Summarycol}>
                <Box className={classes.IconCol}>
                  <Box className={classes.Summaryicon4}>
                    <img src={ppg} alt="ppg" />
                  </Box>
                  <Typography variant="h5" className={classes.icontext}>
                    Total Scans
                  </Typography>
                </Box>
                <CardValue
                  loading={dashboardDataLoading}
                  value={
                    Array.isArray(dashboardData?.cards_data?.total_scans) &&
                    dashboardData.cards_data.total_scans[0]
                  }
                  percentageValue={
                    Array.isArray(dashboardData?.cards_data?.total_scans) &&
                    dashboardData.cards_data.total_scans[1]
                  }
                />
              </Box>
              {/* <Box className={classes.CsvCol}>
                
                  <Button
                    variant="contained"
                    component="span"
                    className={classes.csvbtn}
                  >
                    <BiArrowToBottom size="20" />
                    CSV
                  </Button>
                
              </Box> */}
            </Box>

            <RiskLineChart riskGraphData={dashboardData?.risk_graph_data} />

            <Box className={classes.contentTable}>
              <Box
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  padding: '10px 20px',
                  borderBottom: '1px rgba(218,218,218,0.5) solid',
                  marginBottom: 15,
                }}
              >
                <Typography className={classes.Chartheading}>
                  Overall Risk Percentages
                </Typography>
                {/* <Button className={classes.Viewbtn}>
                  View More{' '}
                  <BiChevronRight size="18" style={{ marginRight: -3 }} />
                </Button> */}
              </Box>
              <Box className={classes.Graphcol}>
                <Box className={classes.chartbox}>
                  <DoughnutChart
                    low={
                      !isNaN(dashboardData?.overall_risk_data?.low)
                        ? dashboardData.overall_risk_data.low
                        : 0
                    }
                    mid={
                      !isNaN(dashboardData?.overall_risk_data?.mid)
                        ? dashboardData.overall_risk_data.mid
                        : 0
                    }
                    high={
                      !isNaN(dashboardData?.overall_risk_data?.high)
                        ? dashboardData.overall_risk_data.high
                        : 0
                    }
                  />
                </Box>

                <AgeWiseEmployeeRiskPercentages
                  ageWiseData={dashboardData?.agewise_data}
                  loading={dashboardDataLoading}
                />
              </Box>
            </Box>
          </Grid>
        </Grid>
        {/* </Box> */}
        {/* </Box> */}
      </Box>
    </div>
  );
}

const useStyles = makeStyles(() => ({
  contentcol: {
    display: 'flex',
  },
  Agerow: {
    display: 'flex',
    flexDirection: 'row',
  },
  EmployeeValue: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginTop: 20,
  },
  chartbox:{
width:'42%',
height:'100%',
'@media (max-width:767px)': {
  width:'100%',
  marginBottom:20,
}
  },
  Thcolor1: {
    width: 12,
    height: 12,
    backgroundColor: '#BDE05B',
    borderRadius: 40,
    marginRight: 10,
    marginLeft: 5,
  },
  Thcolor2: {
    width: 12,
    height: 12,
    backgroundColor: '#E7AE79',
    borderRadius: 40,
    marginRight: 10,
    marginLeft: 5,
  },
  Thcolor3: {
    width: 12,
    height: 12,
    backgroundColor: '#89C3D6',
    borderRadius: 40,
    marginRight: 10,
    marginLeft: 5,
  },
  Thcolor4: {
    width: 12,
    height: 12,
    backgroundColor: '#CB6262',
    borderRadius: 40,
    marginRight: 10,
    marginLeft: 5,
  },
  Thcol2: {
    fontSize: 14,
    marginRight: 45,
  },
  Thcol3: { marginRight: 15, fontSize: 14, color: '#1C1F37', width: '33%' },
  Thcol4: {
    width: 32,
    height: 32,
    backgroundColor: '#DF1010',
    borderRadius: 40,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 12,
    color: '#fff',
  },
  row1: {
    width: '20%',
    backgroundColor: '#BDE05B',
    height: '10px',
    marginRight: 2,
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,
  },
  row2: {
    width: '40%',
    backgroundColor: '#E7AE79',
    height: '10px',
    marginRight: 2,
  },
  row3: {
    width: '30%',
    backgroundColor: '#89C3D6',
    height: '10px',
    marginRight: 2,
  },
  row4: {
    width: '10%',
    backgroundColor: '#CB6262',
    height: '10px',
    marginRight: 2,
    borderTopRightRadius: 10,
    borderBottomRightRadius: 10,
  },
  Chartheading: {
    fontFamily: 'poppins',
    fontSize: 18,
    fontWeight: '600',
    color: '#1C1F37',
  },
  TextLink: {
    color: '#000',
    cursor: 'pointer',
  },
  Low: {
    width: 50,
    height: 50,
    backgroundColor: '#fff',
    borderRadius: '50%',
    boxShadow: '2px 2px 10px 1px rgba(0,0,0,0.3)',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    left: '5%',
    top: '25%',
  },
  Mid: {
    width: 50,
    height: 50,
    backgroundColor: '#fff',
    borderRadius: '50%',
    boxShadow: '2px 2px 10px 1px rgba(0,0,0,0.3)',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    left: '85%',
    top: '35%',
  },
  High: {
    width: 50,
    height: 50,
    backgroundColor: '#fff',
    borderRadius: '50%',
    boxShadow: '2px 2px 10px 1px rgba(0,0,0,0.3)',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    left: '65%',
    top: '82%',
  },
  Leftpart: {
    width: '29%',
    marginRight: '2%',
    '@media (max-width:767px)': {
      width: '96%',
    marginRight: '0%',
    marginLeft:'2%'
    }
  },
  Graphcol: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: 25,
    height: '17.5rem',
    overflow: 'auto',
    '@media (max-width:767px)': {
      flexDirection: 'column',
    }
  },
  Doughnutchart: {
    width: '42%',
    position: 'relative',
  },
  EmployeeGraph: {
    width: '55%',
    paddingLeft: '8%',
    '@media (max-width:767px)': {
      width: '96%',
    paddingLeft: '4%',
    }
  },
  csvbtn: {
    backgroundColor: '#F2F4FD',
    borderRadius: '6px',
    fontFamily: 'poppins',
    fontSize: 12,
    fontWeight: '700',
    color: '#1C1F37',
  },
  Rightpart: {
    width: '69%',
    paddingBottom: 20,
    '@media (max-width:767px)': {
      width: '96%',
      marginLeft:'2%'
    }
  },
  Subheading: {
    fontSize: '16px',
    color: '#6F6F6F',
    textAlign: 'left',
  },
  Valuecol: {
    display: 'flex',
    flexDirection: 'row',
  },
  Valuetext: {
    fontSize: 16,
    fontWeight: '700',
    color: '#1C1F37',
    fontFamily: 'poppins',
  },
  icontext: {
    fontSize: 11,
    color: 'rgba(28, 31, 55, 0.58)',
    marginLeft: 7,
  },
  IconCol: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 5,
  },
  Summaryicon: {
    width: 28,
    height: 28,
    borderRadius: 50,
    backgroundColor: '#D9EF9B',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      width: '100%',
    },
  },
  Summaryicon2: {
    width: 28,
    height: 28,
    borderRadius: 50,
    backgroundColor: '#DCC8F5',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      width: '100%',
    },
  },
  Summaryicon3: {
    width: 28,
    height: 28,
    borderRadius: 50,
    backgroundColor: '#F4BCC6',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      width: '100%',
    },
  },
  Summaryicon4: {
    width: 28,
    height: 28,
    borderRadius: 50,
    backgroundColor: '#F4EDA7',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& img': {
      width: '100%',
    },
  },
  Downarrow: {
    fontSize: '20px',
    color: '#017EFA',
  },
  Optionsbtn: {
    fontSize: '12px',
    fontWeight: '700',
    textTransform: 'capitalize',
  },
  Rightheading: {
    fontSize: 18,
    fontWeight: '600',
    fontFamily: 'Poppins',
    color: '#1C1F37',
  },
  Topright: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: '45px',
  },
  Score: {
    fontSize: '14px',
    color: '#DF0E0E',
    fontWeight: 'bold',
    marginRight: '5px',
  },
  risktext: {
    fontSize: '10px',
    color: '#313131',
  },
  Tdcol4: {
    fontSize: '10px',
    color: '#0A194E',
    width: '30%',
    textAlign: 'left',
  },
  Tdcol5: {
    fontSize: '12px',
    color: '#313131',
    textAlign: 'left',
    width: '30%',
  },
  Tdcol6: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
  },
  tablecol: {
    padding: '0 15px 30px',
    minHeight: '14rem',
  },
  Tdcol3: {
    fontSize: '12px',
    // color: '#E50606',
    fontWeight: '700',
  },
  Tdcol2: {
    fontSize: '12px',
    color: '#313131',
    textAlign: 'left',
  },
  Tdcol1: {
    fontSize: 13,
    fontWeight: '700',
    color: '#000',
    width: '40%',
    textAlign: 'left',
  },
  Linktext: {
    color: '#000',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
      color: '#081A51',
    },
  },
  Tdrow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderBottom: '1px #DADADA solid',
    padding: '10px 0',
  },
  Viewbtn: {
    fontSize: 12,
    color: '#081A51',
    textTransform: 'capitalize',
    padding: '2px 3px !important',
    display: 'flex',
    justifyContent: 'space-between',
    '&:hover': {
      backgroundColor: '#081A51',
      color: '#fff',
      fontSize: 12,
    },
  },
  TitleText: {
    fontSize: 14,
    color: '#1C1F37',
    fontWeight: '700',
    fontFamily: 'Poppins',
  },
  Topcol: {
    padding: '10px',
    borderBottom: '1px #DADADA solid',
    marginBottom: '0',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  Topheading: {
    fontSize: '28px',
    color: '#1C1F37',
    fontWeight: '700',
    textAlign: 'left',
    marginBottom: 5,
    marginTop: 20,
  },
  leftcol: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    backgroundColor: '#081A51',
    width: '200px',
  },
  rightcol: {
    marginLeft: '15%',
    width: '85%',
  },
  contentTable: {
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0px 5px 10px #F1F2FA',
    marginTop: '20px',
    minHeight: 355,
  },
  EmployeeSummary: {
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0px 5px 10px #F1F2FA',
    marginTop: '20px',
    padding: '2%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    '@media (max-width:767px)': {
      flexWrap:'wrap',
      padding: '10px 2% 0',
    }
  },
  Summarycol: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginRight: 10,
    '@media (max-width:767px)': {
      width:'47%',
      marginBottom:10,
      '&:nth-child:(3)':{
        marginRight:0,
      },
      '&:nth-child:(4)':{
        marginRight:0,
      }
    }
  },
  Linechart: {
    marginTop: 20,
  },
}));
