import { useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  makeStyles,
  TextField,
} from '@material-ui/core';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

import { loginUsingEmailAndPassword } from '../../store';

export const LoginForm = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const initialValues = {
    email: '',
    password: '',
  };

  const methods = useForm({
    mode: 'onTouched',
    reValidateMode: 'onChange',
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
  });

  const [passwordVisible, setPasswordVisible] = useState('password');

  const onSubmit = values => {
    const submittedValues = {
      ...values,
      email: values.email.trim(),
    };

    dispatch(
      loginUsingEmailAndPassword(submittedValues, () => {
        methods.reset({
          email: methods.getValues('email'),
          password: '',
        });
      }),
    );
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        noValidate
        spellCheck="false"
      >
        <Controller
          name="email"
          render={({
            field: { ref, ...field },
            fieldState: { error, isTouched },
          }) => (
            <TextField
              autoFocus
              style={{ width: '100%', marginBottom: 30 }}
              placeholder="Email Address"
              type="email"
              {...field}
              inputRef={ref}
              helperText={
                !!error?.message ? (
                  <span className={classes.errorHelperText}>
                    {error.message}
                  </span>
                ) : (
                  ''
                )
              }
            />
          )}
        />
        <Box style={{ position: 'relative' }}>
          <Controller
            name="password"
            render={({
              field: { ref, ...field },
              fieldState: { error, isTouched },
            }) => (
              <TextField
                style={{ width: '100%' }}
                placeholder="Password"
                type={passwordVisible}
                {...field}
                inputRef={ref}
                helperText={
                  !!error?.message ? (
                    <span className={classes.errorHelperText}>
                      {error.message}
                    </span>
                  ) : (
                    ''
                  )
                }
              />
            )}
          />

          <IconButton
            size="small"
            style={{
              color: 'gray',
              position: 'absolute',
              right: 15,
              top: 25,
              transform: 'translateY(-50%)',
            }}
            onClick={() =>
              setPasswordVisible(curState =>
                curState === 'password' ? 'text' : 'password',
              )
            }
          >
            {passwordVisible === 'password' ? (
              <FaEyeSlash size="20" />
            ) : (
              <FaEye size="20" />
            )}
          </IconButton>
        </Box>

        <Button size="large" className={classes.loginbtn} type="submit">
          Submit
        </Button>
      </form>
    </FormProvider>
  );
};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .required('Email Address is required')
    .email('Invalid Email Address'),
  password: Yup.string().required('Password is required'),
});

const useStyles = makeStyles(({ palette }) => ({
  input: {
    border: 'none',
    borderRadius: '10px',
    height: '54px',
    width: '100%',
    marginBottom: '20px',
  },
  loginbtn: {
    background: '#081A51',
    padding: '0 40px',
    width: '100%',
    height: '60px',
    fontSize: '20px',
    borderRadius: '10px',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    boxShadow: '2px 2px 10px 1px rgba(0,0,0,0.3)',
    color: '#fff',
    marginTop: '50px',
    '&:hover': {
      background: '#000',
    },
  },
  errorHelperText: {
    color: palette.error.main,
    fontSize: 12,
    position: 'absolute',
  },
}));
