import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { axios } from '../axios';
import { useSnackbar } from '../hooks/useSnackbar';
import { AVOID_ERROR_CHECKS } from '../api';
import { logout } from '../store';

export const HandleError = ({ children }) => {
  const dispatch = useDispatch();

  const { showErrorSnackbar, showInfoSnackbar } = useSnackbar();

  const [initAxiosSettings, setInitAxiosSettings] = useState(false);

  useEffect(() => {
    const dealWithSuccessResponse = response => {
      if (AVOID_ERROR_CHECKS.includes(response.config.url)) {
        return response;
      }

      if (
        (response.status >= 400 && response.status <= 600) ||
        (!!response.data?.statusCode &&
          response.data.statusCode >= 400 &&
          response.data.statusCode <= 600)
      ) {
        showErrorSnackbar(
          response.data?.message ||
            'Oops! Something went wrong. Please try again later...',
        );
      }

      return response;
    };

    const dealWithErrorResponse = err => {
      if (err.response.status === 403) {
        dispatch(logout());
        showInfoSnackbar(
          'Session timed out! Please login again to continue...',
          10000,
        );
      } else {
        showErrorSnackbar(
          'Oops! Something went wrong. Please try again later...',
        );
      }

      return Promise.reject(err);
    };

    const responseInterceptors = axios.interceptors.response.use(
      dealWithSuccessResponse,
      dealWithErrorResponse,
    );

    setInitAxiosSettings(true);

    return () => {
      axios.interceptors.response.eject(responseInterceptors);
    };
  }, [showErrorSnackbar, dispatch, showInfoSnackbar]);

  return initAxiosSettings ? children : null;
};
