import React, { useEffect, Fragment } from 'react';
import {
  Drawer,
  IconButton,
  makeStyles,
  Typography,
  Button,
  useMediaQuery,
  SwipeableDrawer,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { BiChevronLeft } from 'react-icons/bi';
import { NavLink, useHistory } from 'react-router-dom';
import { SIDEBAR } from '../global-constants';
import { Box } from '@mui/system';
import Dashboardicon from '../images/Chart_fill.png';
import Wellnessicon from '../images/Chart_alt.png';
import logoutimg from '../images/logout.png';
import Logo from '../images/careplix_corporate_logo.png';
import { logout, sidebarActions } from '../store';

export const Layout = ({ children }) => {
  const classes = useStyles();

  const { listen } = useHistory();

  const matchesUp813p = useMediaQuery('(min-width: 813px)', {
    defaultMatches: document.body.getBoundingClientRect().width > 812,
  });

  const dispatch = useDispatch();

  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  const sidebarOpen = useSelector(state => state.sidebar.open);

  useEffect(() => {
    const sidebarOpen = localStorage.getItem(SIDEBAR);

    if (isLoggedIn && !sidebarOpen) {
      if (document.body.getBoundingClientRect().width > 812) {
        dispatch(sidebarActions.openSidebar());
      } else {
        dispatch(sidebarActions.closeSidebar());
      }
    }
  }, [isLoggedIn, dispatch]);

  useEffect(() => {
    if (isLoggedIn) {
      localStorage.setItem(SIDEBAR, sidebarOpen ? 'open' : 'closed');
    }
  }, [isLoggedIn, sidebarOpen]);

  useEffect(() => {
    let unlisten = () => {};

    if (isLoggedIn && !matchesUp813p) {
      unlisten = listen(() => {
        dispatch(sidebarActions.closeSidebar());
      });
    }

    return unlisten;
  }, [dispatch, isLoggedIn, listen, matchesUp813p]);

  const drawerContent = (
    <Fragment>
      <IconButton
        onClick={() => dispatch(sidebarActions.closeSidebar())}
        classes={{ root: classes.drawerToggleIcon }}
      >
        <BiChevronLeft size="20" color="#445BFF" style={{ fontSize: 20 }} />
      </IconButton>
      <Box className={classes.Toplogo}>
        <img src={Logo} alt="logo" />
      </Box>
      <Typography
        style={{
          color: '#51CBFF',
          fontFamily: 'poppins',
          fontSize: 13,
          marginLeft: 10,
          marginBottom: 10,
        }}
      >
        MAIN MENU
      </Typography>
      <nav>
        <ul className={classes.navlist}>
          <li>
            <NavLink
              activeClassName={classes.activeNav}
              className={classes.Leftnavlink}
              to="/dashboard"
            >
              <img
                src={Dashboardicon}
                alt="Dashboard"
                style={{ marginRight: 10 }}
              />{' '}
              Dashboard
            </NavLink>
          </li>
          <li>
            <NavLink
              activeClassName={classes.activeNav}
              className={classes.Leftnavlink}
              to="/wellness-analytics"
            >
              <img
                src={Wellnessicon}
                alt="Wellness"
                style={{ marginRight: 10 }}
              />{' '}
              Wellness Analytics
            </NavLink>
          </li>
          {!matchesUp813p && (
            <li className={classes.ResponsiveLink}>
              <Button
                className={classes.Leftnavlink}
                onClick={() => dispatch(logout())}
              >
                <img src={logoutimg} alt="logout" style={{ marginRight: 10 }} />{' '}
                Logout
              </Button>
            </li>
          )}
          {/* <li>
                <NavLink activeClassName={classes.activeNav} className={classes.Leftnavlink} to="">
                  Search Employee
                </NavLink>
              </li>
              <li>
                <NavLink activeClassName={classes.activeNav} className={classes.Leftnavlink} to="">
                  Settings
                </NavLink>
              </li> */}
        </ul>
      </nav>
    </Fragment>
  );

  return (
    <div style={{ display: 'flex' }}>
      {isLoggedIn &&
        (matchesUp813p ? (
          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={sidebarOpen}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            {drawerContent}
          </Drawer>
        ) : (
          <SwipeableDrawer
            anchor="left"
            open={sidebarOpen}
            onClose={() => dispatch(sidebarActions.closeSidebar())}
            onOpen={() => dispatch(sidebarActions.openSidebar())}
            classes={{ paper: classes.drawerPaper }}
          >
            {drawerContent}
          </SwipeableDrawer>
        ))}

      <main
        className={[
          classes.content,
          matchesUp813p ? classes.contentAbove813p : '',
          sidebarOpen && matchesUp813p ? classes.contentShift : '',
        ].join(' ')}
      >
        {children}
      </main>
    </div>
  );
};

const drawerWidth = 25;

const useStyles = makeStyles(({ spacing, transitions }) => ({
  drawer: {
    width: spacing(drawerWidth),
  },
  drawerPaper: {
    width: spacing(drawerWidth),
    backgroundColor: '#081A51',
  },
  Logoutbtn: {
    display: 'none',
    '@media (max-width:767px)': {
      display: 'block',
      color: '#fff',
    },
  },
  drawerToggleIcon: {
    backgroundColor: 'white',
    position: 'inherit',
    left: spacing(drawerWidth),
    transform: 'translateX(-60%)',
    width: 30,
    height: 30,
    padding: 0,
    marginTop: 20,
    zIndex: 1201,
    '&:hover': {
      backgroundColor: '#e5eaf9',
      color: '#fff !important',
    },
  },
  Toplogo: {
    marginTop: 40,
    marginBottom: 40,
    '& img': {
      width: '80%',
    },
  },
  activeNav: {
    background: '#1B2B65',
  },
  Leftnavlink: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    color: '#fff',
    height: 55,
    width: '100%',
    fontSize: 13,
    paddingLeft: 10,
    textDecoration: 'none',
    justifyContent: 'flex-start',
    textTransform: 'capitalize',
  },
  content: {
    flexGrow: 1,
    transition: transitions.create('margin', {
      easing: transitions.easing.sharp,
      duration: transitions.duration.leavingScreen,
    }),
  },
  contentAbove813p: {
    marginLeft: spacing(-1 * drawerWidth),
  },
  navlist: {
    margin: 0,
    padding: '0 0 0 10px',
    listStyle: 'none',
    '& li': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  Navlink: {
    background: '',
  },
  contentShift: {
    transition: transitions.create('margin', {
      easing: transitions.easing.easeOut,
      duration: transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));
