import React from 'react';
import { Box, Button, IconButton, Typography, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { BiMenu } from 'react-icons/bi';

import { logout, sidebarActions } from '../store';

import Noimage from '../images/no-image.png';

export function Header() {
  const classes = useStyles();
  const matchesUp813p = useMediaQuery('(min-width: 813px)', {
    defaultMatches: document.body.getBoundingClientRect().width > 812,
  });

  const dispatch = useDispatch();

  const sidebarOpen = useSelector(state => state.sidebar.open);
  const userDetails = useSelector(state => state.profileDetails.userDetails);

  return (
    <div>
      <Box className={classes.contentcol}>
        {(!sidebarOpen || !matchesUp813p) && (
          <IconButton
            onClick={() => dispatch(sidebarActions.openSidebar())}
            style={{ width: 45, height: 45, marginRight: 15 }}
          >
            <BiMenu color="#445BFF" />
          </IconButton>
        )}

        <Box className={classes.TopHeader}>
          <Box className={classes.Avatar}>
            <img src={userDetails?.profile_pic || Noimage} alt="avatar" />
          </Box>
          <Box style={{ justifyContent: 'flex-start' }}>
            <Typography variant="h3" className={classes.Username}>
              {userDetails?.name || 'John Doe'}
            </Typography>
            <Typography variant="p">
              {userDetails?.email || 'john.doe@careplix.com'}
            </Typography>
          </Box>
        </Box>
        <Box className={classes.LogoutCol}>
          <Button
            className={classes.Logoutbtn}
            onClick={() => dispatch(logout())}
          >
            Logout
          </Button>
        </Box>
      </Box>
    </div>
  );
}

const useStyles = makeStyles(() => ({
  contentcol: {
    padding: '15px 2%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    width: '100%',
    borderBottom: '1px #DADADA solid',
  },
  LogoutCol:{
    borderLeft: '1px #DADADA solid',
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    right: 28,
    top: 22,
    '@media (max-width:767px)': {
      display:'none'
    }
  },
  TopHeader:{
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '@media (max-width:767px)': {
      flexWrap:'wrap'
    }
  },
  Logoutbtn: {
    textTransform: 'capitalize',
    fontSize: '14px',
    color: '#000',
    marginLeft: '15px',
    borderRadius: '5px',
    paddingLeft: '15px',
    paddingRight: '15px',
    '&:hover': {
      background: '#081A51',
      color: '#fff',
    },
  },
  Avatar: {
    marginRight: '10px',
    '& img': {
      width: 45,
      height: 45,
      objectFit: 'cover',
      borderRadius: 10,
    },
    '@media (max-width:767px)': {
      display:'none'
    }
  },
  Username: {
    fontSize: '18px',
    textAlign: 'left',
    fontWeight: '700',
  },
}));
