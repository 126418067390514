import React, { useEffect, useRef, useState } from 'react';
import { Box, Typography, Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory, useParams } from 'react-router-dom';
import { BiChevronLeft } from 'react-icons/bi';
import isValid from 'date-fns/isValid';
import format from 'date-fns/format';
import differenceInYears from 'date-fns/differenceInYears';

import { Header } from '../components/Header';
import { ScanHistory } from '../containers/ScanHistory';
import { useAxios } from '../hooks/useAxios';
import { DoughnutChart } from '../containers/DoughnutChart';
import { RiskLineChart } from '../containers/RiskLineChart';
import URIS from '../api';
import { HealthRiskScore } from '../containers/HealthRiskScore';

import Noimage from '../images/no-image.png';

export function EmployeeDetails() {
  const classes = useStyles();

  const { employeeId } = useParams();
  const {
    location: { state: locationState },
    push,
    goBack,
  } = useHistory();

  const [open, setOpen] = useState(false);

  const anchorRef = useRef(null);
  const prevOpen = useRef(open);

  const {
    myFetch: getEmployeeDetails,
    isLoading: employeeDetailsLoading,
    responseData: employeeDetails,
  } = useAxios();

  const { profile_data: [profileData] = [{}] } = employeeDetails || {};

  useEffect(() => {
    getEmployeeDetails({
      loadingMessage: 'Loading User Details',
      url: URIS.EMPLOYEE_DETAILS,
      payload: {
        channel_id: ['risk_graph', 'profile', 'overall_risk'],
        user_id: employeeId,
      },
    }).catch(err => {});
  }, [employeeId, getEmployeeDetails]);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  // const handleClose = event => {
  //   if (anchorRef.current && anchorRef.current.contains(event.target)) {
  //     return;
  //   }

  //   setOpen(false);
  // };

  // const handleListKeyDown = event => {
  //   if (event.key === 'Tab') {
  //     event.preventDefault();
  //     setOpen(false);
  //   }
  // };

  return (
    <div>
      <Header />
      <Box sx={{ flexGrow: 1, padding: '0 3%' }}>
        <Grid container spacing={2}>
          <Grid className={classes.Leftpart}>
            <Box
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                marginTop: 25,
              }}
            >
              <Button
                className={classes.Backbtn}
                onClick={() => {
                  if (locationState === 'wa') {
                    goBack();
                  } else {
                    push('/dashboard');
                  }
                }}
              >
                <BiChevronLeft size="20" style={{ marginLeft: -5 }} /> Back
              </Button>
            </Box>
            <Box className={classes.Topcol}>
              <Typography variant="h2" className={classes.Topheading}>
                Scan Summary
              </Typography>
              <Box className={classes.Dropdown}>
                <Box className={classes.Action}>
                  {/* <Select options={options} onChange={(values) => this.setValues(values)} placeholder="Action" className={classes.select} /> */}
                  <Button
                    className={classes.Optionsbtn}
                    ref={anchorRef}
                    aria-controls={open ? 'menu-list-grow' : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                  >
                    Monthly
                    {/* <BiChevronDown className={classes.Downarrow} /> */}
                  </Button>
                  {/* <Popper
                    open={open}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    transition
                    disablePortal
                    style={{ zIndex: '999999' }}
                  >
                    {({ TransitionProps, placement }) => (
                      <Grow
                        {...TransitionProps}
                        style={{
                          transformOrigin:
                            placement === 'bottom'
                              ? 'center top'
                              : 'center bottom',
                        }}
                      >
                        <Paper
                          style={{
                            boxShadow: 'none',
                            width: '110px',
                            border: '1px #AEAEAE solid',
                            borderRadius: '10px',
                          }}
                        >
                          <ClickAwayListener onClickAway={handleClose}>
                            <MenuList
                              autoFocusItem={open}
                              id="menu-list-grow"
                              onKeyDown={handleListKeyDown}
                            >
                              <MenuItem
                                onClick={handleClose}
                                style={{
                                  color: '#7087A7',
                                  fontSize: '12px',
                                  paddingLeft: '10px',
                                  paddingRight: '10px',
                                }}
                              >
                                Save
                              </MenuItem>
                              <MenuItem
                                onClick={handleClose}
                                style={{
                                  color: '#7087A7',
                                  fontSize: '12px',
                                  paddingLeft: '10px',
                                  paddingRight: '10px',
                                }}
                              >
                                <Link style={{ color: '#7087A7' }}>Close</Link>
                              </MenuItem>
                              <MenuItem
                                onClick={handleClose}
                                style={{
                                  color: '#7087A7',
                                  fontSize: '12px',
                                  paddingLeft: '10px',
                                  paddingRight: '10px',
                                }}
                              >
                                Print Care Plan
                              </MenuItem>
                              <MenuItem
                                onClick={handleClose}
                                style={{
                                  color: '#7087A7',
                                  fontSize: '12px',
                                  paddingLeft: '10px',
                                  paddingRight: '10px',
                                }}
                              >
                                Unfinalize
                              </MenuItem>
                            </MenuList>
                          </ClickAwayListener>
                        </Paper>
                      </Grow>
                    )}
                  </Popper> */}
                </Box>
              </Box>
            </Box>

            <RiskLineChart riskGraphData={employeeDetails?.risk_graph_data} />

            <Typography
              variant="h4"
              className={classes.Subheading}
              style={{ marginTop: '3rem' }}
            >
              Scan History
            </Typography>

            <ScanHistory userId={employeeId} />
          </Grid>
          <Grid className={classes.Rightpart}>
            <Box className={classes.contentTable}>
              <Box className={classes.Noimage}>
                {profileData?.profile_pic ? (
                  <img
                    style={{
                      width: 100,
                      height: 100,
                      objectFit: 'cover',
                      borderRadius: '50%',
                    }}
                    src={profileData.profile_pic}
                    alt={profileData?.name || 'John Doe'}
                  />
                ) : (
                  <img src={Noimage} alt="" />
                )}
              </Box>
              <Typography variant="h4" className={classes.Name}>
                {employeeDetailsLoading
                  ? 'Loading Name...'
                  : profileData?.name || 'John Doe'}
              </Typography>
              <Typography className={classes.Address}>
                {/* EID8907771 , Kasba, Kolkata */}
                {profileData?.location || '-NA-'}
              </Typography>
              <Box className={classes.infocol}>
                <Box className={classes.dateofbirth}>
                  <strong>DOB</strong> :{' '}
                  {isValid(new Date(profileData?.dob))
                    ? format(new Date(profileData.dob), 'dd-MM-yyyy')
                    : '-NA-'}
                  &nbsp; (
                  {isValid(new Date(profileData?.dob))
                    ? differenceInYears(new Date(), new Date(profileData.dob)) +
                      '+'
                    : '-NA-'}{' '}
                  yrs)
                </Box>
                <Box className={classes.gender}>
                  <strong>Gender</strong> : {profileData?.gender || '-NA-'}
                </Box>
              </Box>
              <Box className={classes.infocol}>
                <Box className={classes.height}>
                  <strong>Height</strong> :{' '}
                  {isNaN(+profileData?.height) || profileData?.height === ''
                    ? '-NA-'
                    : profileData.height + ' cm'}
                </Box>
                <Box className={classes.weight}>
                  <strong>Weight</strong> :{' '}
                  {isNaN(+profileData?.weight) || profileData?.weight === ''
                    ? '-NA-'
                    : profileData.weight + ' kg'}
                </Box>
              </Box>
              <Box className={classes.infocol}>
                <Box className={classes.bmi}>
                  <strong>BMI</strong> : {profileData?.bmi || '-NA-'}
                </Box>
              </Box>
              <Box className={classes.infocol}>
                <Box className={classes.bmi}>
                  <strong>Health Risk Score</strong> :{' '}
                  <HealthRiskScore>
                    {profileData?.health_risk_score}
                  </HealthRiskScore>
                </Box>
              </Box>
            </Box>
            <Typography className={classes.Toptext}>
              Risk Percentages
            </Typography>
            <Box className={classes.contentTable} height="18rem">
              <DoughnutChart
                low={
                  !isNaN(employeeDetails?.overall_risk_data?.low)
                    ? employeeDetails.overall_risk_data.low
                    : 0
                }
                mid={
                  !isNaN(employeeDetails?.overall_risk_data?.mid)
                    ? employeeDetails.overall_risk_data.mid
                    : 0
                }
                high={
                  !isNaN(employeeDetails?.overall_risk_data?.high)
                    ? employeeDetails.overall_risk_data.high
                    : 0
                }
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

const useStyles = makeStyles(() => ({
  leftcol: {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    backgroundColor: '#081A51',
    width: '200px',
  },
  Noimage: {
    marginBottom: 15,
    '& img': {
      width: '30%',
    },
  },
  Toptext:{
    marginTop: 55,
    fontFamily: 'poppins',
    fontSize: 16,
    textAlign: 'left',
    fontWeight: '600',
    '@media (max-width:767px)': {
      marginTop: 15,
    }
  },
  Btncol: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
    marginTop: 10,
  },
  Morebtn: {
    fontFamily: 'poppins',
    fontSize: 14,
    paddingLeft: 15,
    fontWeight: '500',
    textTransform: 'capitalize',
    '&:hover': {
      backgroundColor: '#081A51',
      color: '#fff',
    },
  },
  Backbtn: {
    fontFamily: 'poppins',
    fontSize: 14,
    fontWeight: '600',
    textTransform: 'capitalize',
    paddingRight: 10,
    '&:hover': {
      backgroundColor: '#081A51',
      color: '#fff',
    },
  },
  Address: {
    fontFamily: 'poppins',
    fontSize: 13,
    marginBottom: 35,
  },
  Name: {
    fontFamily: 'poppins',
    fontSize: 18,
    fontWeight: '700',
    marginBottom: 10,
  },
  dateofbirth: {
    fontFamily: 'poppins',
    fontSize: 12,
    marginRight: 20,
    fontWeight: '500',
  },
  bmi: {
    fontFamily: 'poppins',
    fontSize: 12,
    fontWeight: '500',
  },
  gender: {
    fontFamily: 'poppins',
    fontSize: 12,
    fontWeight: '500',
  },
  height: {
    fontFamily: 'poppins',
    fontSize: 12,
    marginRight: 20,
    fontWeight: '500',
  },
  weight: {
    fontFamily: 'poppins',
    fontSize: 12,
    fontWeight: '500',
  },
  Doughnutchart: {
    width: '94%',
    position: 'relative',
  },
  Low: {
    width: 50,
    height: 50,
    backgroundColor: '#fff',
    borderRadius: '50%',
    boxShadow: '2px 2px 10px 1px rgba(0,0,0,0.3)',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    left: '5%',
    top: '25%',
  },
  Mid: {
    width: 50,
    height: 50,
    backgroundColor: '#fff',
    borderRadius: '50%',
    boxShadow: '2px 2px 10px 1px rgba(0,0,0,0.3)',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    left: '85%',
    top: '35%',
  },
  High: {
    width: 50,
    height: 50,
    backgroundColor: '#fff',
    borderRadius: '50%',
    boxShadow: '2px 2px 10px 1px rgba(0,0,0,0.3)',
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    left: '65%',
    top: '82%',
  },
  infocol: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: 10,
  },
  contentTable: {
    width: '100%',
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0px 5px 10px #F1F2FA',
    marginTop: '10px',
    marginBottom: '3rem',
    textAlign: 'center',
    padding: '20px 0',
  },
  Linechart: {
    marginBottom: 30,
  },
  Subheading: {
    fontFamily: 'poppins',
    fontSize: '18px',
    fontWeight: '600',
    color: '#1C1F37',
    marginBottom: '15px',
    textAlign: 'left',
  },
  Viewbtn: {
    fontFamily: 'poppins',
    fontSize: 14,
    textTransform: 'capitalize',
    color: '#081A51',
  },
  Datatable: {
    width: '100%',
    padding: '2%',
    margin: '10px 0',
    backgroundColor: '#fff',
    borderRadius: 10,
    boxShadow: '0px 5px 10px #F1F2FA',
  },
  Throw: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '1px rgba(218,218,218,0.5) solid',
    paddingBottom: 10,
    '@media (max-width:767px)': {
    display:'none'
    }
  },
  Tdrow: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px 0',
    alignItems: 'center',
    borderBottom: '1px rgba(218,218,218,0.5) solid',
    '@media (max-width:767px)': {
      flexWrap:'wrap'
    }
  },
  col1: {
    width: '30%',
    fontFamily: 'poppins',
    fontSize: 14,
    color: '#000',
    fontWeight: '600',
    textAlign: 'left',
  },
  col2: {
    width: '30%',
    fontFamily: 'poppins',
    fontSize: 14,
    color: '#000',
    fontWeight: '600',
    textAlign: 'left',
  },
  col3: {
    width: '25%',
    fontFamily: 'poppins',
    fontSize: 14,
    color: '#000',
    fontWeight: '600',
    textAlign: 'left',
  },
  col4: {
    width: '25%',
    fontFamily: 'poppins',
    fontSize: 14,
    color: '#000',
    fontWeight: '600',
    textAlign: 'left',
  },
  col5: {
    width: '15%',
    fontFamily: 'poppins',
    fontSize: 14,
    color: '#000',
    fontWeight: '600',
    textAlign: 'left',
  },
  col6: {
    width: '15%',
    fontFamily: 'poppins',
    fontSize: 14,
    color: '#000',
    fontWeight: '600',
  },
  Tdcol1: {
    width: '30%',
    fontFamily: 'poppins',
    fontSize: 14,
    color: '#000',
    fontWeight: '400',
    textAlign: 'left',
    '@media (max-width:767px)': {
      width: '86%',
    }
  },
  Tdcol2: {
    width: '30%',
    fontFamily: 'poppins',
    fontSize: 14,
    color: '#000',
    fontWeight: '400',
    textAlign: 'left',
  },
  Tdcol3: {
    width: '25%',
    fontFamily: 'poppins',
    fontSize: 14,
    color: '#000',
    fontWeight: '400',
    textAlign: 'left',
  },
  Tdcol4: {
    width: '15%',
    fontFamily: 'poppins',
    fontSize: 14,
    color: '#000',
    fontWeight: '400',
    textAlign: 'left',
  },
  Midscore: {
    width: '15%',
    fontFamily: 'poppins',
    fontSize: 14,
    color: '#E9AB0D',
    fontWeight: '400',
    textAlign: 'left',
  },
  LowScore: {
    width: '15%',
    fontFamily: 'poppins',
    fontSize: 14,
    color: '#65C22C',
    fontWeight: '400',
    textAlign: 'left',
  },
  Higscore: {
    width: '15%',
    fontFamily: 'poppins',
    fontSize: 14,
    color: '#D00C0C',
    fontWeight: '400',
    textAlign: 'left',
  },
  Tdcol6: {
    width: '15%',
    fontFamily: 'poppins',
    fontSize: 14,
    color: '#000',
    fontWeight: '400',
  },
  Action: {
    marginTop: 15,
  },
  Optionsbtn: {
    fontFamily: 'poppins',
    fontSize: 14,
    textTransform: 'capitalize',
    color: '#717171',
    fontWeight: '400',
  },
  Downarrow: {
    fontSize: '20px',
    color: '#017EFA',
  },
  rightcol: {
    marginLeft: '15%',
    width: '85%',
    paddingBottom: 30,
  },
  Topheading: {
    fontFamily: 'poppins',
    fontSize: 18,
    fontWeight: '700',
    textAlign: 'left',
    marginTop: 20,
  },
  Topcol: {
    display: 'flex',
    flexDirection: 'row',
    padding: '10px 0',
    justifyContent: 'space-between',
    marginBottom: 10,
  },
  Searchcol: {
    width: '30%',
    marginRight: 30,
    position: 'relative',
    '& .MuiInput-input': {
      paddingLeft: 25,
    },
    '& .MuiInputLabel-formControl': {
      paddingLeft: 25,
      fontSize: 14,
      fontFamily: 'poppins',
    },
  },
  Searchicon: {
    position: 'absolute',
    left: 0,
    top: 20,
  },
  Leftpart: {
    width: '68%',
    '@media (max-width:767px)': {
      width:'96%',
      marginLeft:'2%'
    }
  },
  Rightpart: {
    width: '30%',
    marginLeft: '2%',
    marginTop: 30,
    '@media (max-width:767px)': {
      width:'96%',
      marginLeft:'2%',
      marginTop:5,
    }
  },
}));
