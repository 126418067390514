import { uiActions } from '../slices/ui-slice';
import { authActions } from '../slices/auth-slice';
import { profileDetailsActions } from '../slices/profile-details-slice';
import { sidebarActions } from '../slices/sidebar-slice';
import { axios } from '../../axios';
import URIS from '../../api';
import { ACCESS_TOKEN, SIDEBAR, USER_DETAILS } from '../../global-constants';

export const loginUsingSavedCredentials = () => {
  return dispatch => {
    dispatch(
      uiActions.setLoading({
        networkFetching: true,
        loadingMessage: 'Logging you in',
      }),
    );

    const authTokenSavedInLocalStorage = localStorage.getItem(ACCESS_TOKEN);
    const userDetailsSavedInLocalStorage = localStorage.getItem(USER_DETAILS)
      ? JSON.parse(localStorage.getItem(USER_DETAILS))
      : null;

    if (!!authTokenSavedInLocalStorage) {
      dispatch(
        authActions.changeAuthState({
          authToken: authTokenSavedInLocalStorage,
        }),
      );

      dispatch(
        profileDetailsActions.setUserDetails(
          userDetailsSavedInLocalStorage || {},
        ),
      );
    } else {
      localStorage.removeItem(ACCESS_TOKEN);
      localStorage.removeItem(USER_DETAILS);
    }

    dispatch(
      uiActions.setLoading({
        networkFetching: false,
        loadingMessage: null,
      }),
    );
  };
};

export const loginUsingEmailAndPassword = ({ email, password }, onError) => {
  return async dispatch => {
    dispatch(
      uiActions.setLoading({
        networkFetching: true,
        loadingMessage: 'Logging you in',
      }),
    );

    try {
      const { data } = await axios.post(URIS.SIGN_IN, {
        email,
        password,
      });

      if (data.statusCode === 200 || data.statusCode === 201) {
        dispatch(
          authActions.changeAuthState({
            authToken: data?.user?.id_token,
          }),
        );

        dispatch(profileDetailsActions.setUserDetails(data?.user || {}));

        localStorage.setItem(ACCESS_TOKEN, data?.user?.id_token);
        localStorage.setItem(USER_DETAILS, JSON.stringify(data?.user));
      } else {
        throw new Error('something');
      }
    } catch (error) {
      if (onError) {
        onError();
      }
    }

    dispatch(
      uiActions.setLoading({
        networkFetching: false,
        loadingMessage: null,
      }),
    );
  };
};

export const logout = idToken => {
  return dispatch => {
    // if (idToken) {
    //   axios.post(URIS.LOGOUT, {
    //     id_token: idToken,
    //   });
    // }

    dispatch(authActions.logout());
    dispatch(profileDetailsActions.reset());
    dispatch(sidebarActions.closeSidebar());
    localStorage.removeItem(SIDEBAR);
    localStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(USER_DETAILS);
  };
};
