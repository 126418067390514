import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  authToken: null,
  isLoggedIn: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    changeAuthState(state, { payload }) {
      state.authToken = payload.authToken;
      state.isLoggedIn = true;
    },
    logout(state) {
      state.authToken = null;
      state.isLoggedIn = false;
    },
  },
});

export const { actions: authActions, reducer: authReducer } = authSlice;
